import { getCookie } from "./index";

// const API_URL = process.env.REACT_APP_API;
export const API_URL = "https://api.anonnchat.fun";

export const WS_URL = "wss://fhy7mlo4pg.execute-api.us-east-1.amazonaws.com/dev/";

export const TOKEN_KEY = "annon_token";

export const TOKEN = getCookie(TOKEN_KEY) || "";

export const AUTH_HEADER = { Authorization: `Bearer ${TOKEN}` };

export const AUTH_TOKEN_TIMEOUT = 2; //2hrs

export const GROUP_EMOJI_TIMEOUT = 2; //2hrs


// dispatch type


// actions
export const WS_SEND_ACTION = "sendMessage";

// events
export const ADD_CONNECTION = "event.connect";
export const CHAT = "event.chat";
export const IMAGE = "event.image";
export const NOTIFICATION = "event.notify";
export const ACTIVE_USERS = "event.active-users";
