import React from "react";
import "./process.scss";

export default function ProcessImage({ data, retry }) {
  const resend = () => {
    retry({ status: "retry", blob: data.blob });
  };
  const cancel = () => {
    retry(false);
  };
  return (
    <div className="process">
      <img src={data.blob} alt="" />
      <div className="title">
        {data.status === "pending"
          ? "Sending Image"
          : data.status === "error"
          ? "Error Sending Image"
          : "Image Sent Succesfully"}
      </div>
      <div className="body">
        <div className="process-options">
          {data.status === "error" ? (
            <span class="material-symbols-rounded" onClick={resend}>
              upload
            </span>
          ) : data.status === "pending" ? (
            <svg
              style={{ "--progress": data.progress }}
              width="22"
              height="22"
              viewBox="0 0 22 22"
              class="circular-progress"
            >
              <circle class="fg"></circle>
            </svg>
          ) : (
            <>
              {/* <span class="material-symbols-rounded complete">check_circle</span> */}
            </>
          )}

          {data.status !== "success" && (
            <span
              class="material-symbols-rounded"
              id={data.status === "pending" && "fade"}
              onClick={cancel}
            >
              close
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
