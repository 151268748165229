import React, { useCallback, useEffect, useState } from "react";
import "./sendimage.scss";
import Notify from "../notification/notify";
import Loader from "../loader/loader";
import axios from "axios";
import { uploadImage } from "../../services/api";
import { blobToBase64, imageUrlToBase64 } from "../../utils";
import { API_URL } from "../../utils/constant";

export default function SendImage({
  data,
  lowRes,
  id,
  cancel,
  reselect,
  input,
  onChange,
  imgLink,
  thumb,
  process,
  setProcess,
}) {
  const [err, setErr] = useState(false);
  const [blob, setBlob] = useState();
  const [lowBlob, setLowBlob] = useState();
  const [progress, setProgress] = useState(0);

  const GetHighRes = useCallback(async () => {
    const formData = {
      room_id: id,
      image_b64: blob,
    };

    const resp = await axios.post(`${API_URL}/room/upload-image`, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    });
    if (resp.status === 200) {
      return resp.data.message;
    } else {
      setErr("Failed to send image");
      setTimeout(() => {
        setErr(false);
      }, 4000);
    }
  }, [blob, id]);

  const GetLowRes = useCallback(async () => {
    const formData = {
      room_id: id,
      image_b64: lowBlob,
    };
    const resp = await uploadImage(formData);
    if (resp.status === 200) {
      return resp.data.message;
    } else {
      setErr("Failed to send image");
      setTimeout(() => {
        setErr(false);
      }, 4000);
    }
  }, [id, lowBlob]);

  useEffect(() => {
    imageUrlToBase64(data)
      .then((base64Image) => {
        setBlob(base64Image);
      })
      .catch((error) => {
        console.error("Error converting image to Base64:", error);
      });
    imageUrlToBase64(lowRes)
      .then((base64Image) => {
        setLowBlob(base64Image);
      })
      .catch((error) => {
        console.error("Error converting image to Base64:", error);
      });
  }, [data, lowRes, thumb]);

  useEffect(() => {
    progress > 0 &&
      thumb &&
      setProcess({
        status: "pending",
        blob: thumb,
        progress: progress,
      });
  }, [progress, lowBlob, thumb]);

  const handleUpload = useCallback(async () => {
    try {
      const lowImg = GetLowRes();
      const highImg = GetHighRes();

      Promise.all([highImg, lowImg])
        .then(([highResponse, lowResponse]) => {
          imgLink({
            high: highResponse,
            low: lowResponse,
          });
          setTimeout(() => {
            setProcess({
              status: "success",
              blob: thumb,
            });
          }, 1000);
          setTimeout(() => {
            setProcess(false);
          }, 4000);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setProcess({
            status: "error",
            blob: thumb,
          });
        });
    } catch (error) {
      console.error(error);
    }
  }, [GetLowRes, GetHighRes, imgLink, setProcess, thumb]);

  useEffect(() => {
    if (process.status === "retry") {
      handleUpload();
    }
    if (process.status === "cancel") {
      cancel(true);
    }
  }, [process, handleUpload]);

  if (!process) {
    return (
      <div className="sendimage">
        {err && <Notify msg={err} />}
        <div>
          <div id="img-title">
            <span>Send Image</span>
            <span class="material-symbols-rounded" onClick={() => cancel(true)}>
              close
            </span>
          </div>
          <div id="img-container">
            <img src={data} alt="sendImage" />
          </div>
          <div id="img-options">
            <div>
              <span class="material-symbols-rounded" onClick={reselect}>
                photo_library
              </span>
              <input
                type="text"
                value={input}
                onChange={onChange}
                maxLength={100}
                placeholder="Add a caption..."
              />
              <span class="material-symbols-rounded" onClick={handleUpload}>
                send
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
