// import './logo.svg'
import { Link } from "react-router-dom";
function Logo() {
  const style = {
    width: "68px",
    height: "68px",
  };
  return (
    <Link to={"/homepage"}>
      <img
        style={style}
        src={process.env.PUBLIC_URL + "/logo.png"}
        alt="AnonnChat"
        id="logo_label"
      />
    </Link>
  );
}

export default Logo;
