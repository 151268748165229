import { replaceLineBreaksWithSpace, truncateString } from "../../utils";
import "./notify.scss";
export function notify(title, body) {
  // try {
  const notification = () =>
    new Notification(title, {
      body: truncateString(replaceLineBreaksWithSpace(body), 200),
      img: "https://realtime-annon.vercel.app/logo192.png",
      vibrate: [200, 100, 200],
    });

  const newNotification = () => {
    if (Notification !== undefined && Notification.permission === "granted") {
      notification();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          notification();
        }
      });
    }
  };
  return newNotification();
  // } catch (error) {
  //   console.log(error);
  // }
}

export default function Notify({ msg }) {
  return (
    <div className="notifyContainer">
      <div className="customalert">{msg}</div>
    </div>
  );
}
