import React, { useEffect, useRef, useState } from "react";
import Notify from "../notification/notify";
import { compressImage, truncateString } from "../../utils";
import TextareaAutosize from "react-textarea-autosize";
import SendImage from "../sendImage";
import Loader from "../loader/loader";
import ProgressiveLazyImage from "../ProgressiveImage";

export default function MessageBox({
  id,
  state,
  sendMsg,
  userInput,
  userCaption,
  input,
  caption,
  msgTextArea,
  whiteSpace,
  copyNotify,
  replyMsg,
  closeReply,
  focusMsg,
  process,
  setProcess,
}) {
  const sendMsgRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [lowRes, setLowRes] = useState(null);
  const [errMsg, setErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState(null);
  const blurMsg = (e) => {
    e.target.parentElement.parentElement.style.borderBottomColor = "#0D1117";
  };

  useEffect(() => {
    const clean = sendMsgRef.current;
    if (clean) {
      clean.scrollTo({
        left: whiteSpace(input) ? 0 : clean.scrollWidth,
        behavior: whiteSpace(input) ? "instant" : "smooth",
      });
    }
  }, [input, whiteSpace]);
  const handleImageChange = async (e) => {
    setImageUrl(false);
    setLoading(true);
    const imageFile = e.target.files[0];

    try {
      const compressedUrl = await compressImage(imageFile, 1080);
      const compressedLowRes = await compressImage(imageFile, 20);
      const compressedThumb = await compressImage(imageFile, 100);
      setImageUrl(compressedUrl);
      setLowRes(compressedLowRes);
      setThumb(compressedThumb);
      setLoading(false);
    } catch (error) {
      setErrMsg("Error compressing image");
      setLoading(false);
      setTimeout(() => {
        setErrMsg(false);
      }, 4000);
    }
  };

  const sendImg = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = handleImageChange;
    fileInput.click();
  };
  const cancelImage = (data) => {
    setImageUrl(false);
    setProcess(false);
    setLowRes(false);
    msgTextArea.current.focus();
  };

  const sendImage = async (data) => {
    sendMsg({ image: data });
    setImageUrl(false);
    setLowRes(false);
  };

  useEffect(() => {
    if (!process) {
      cancelImage();
    }
  }, [process]);

  return (
    <>
      {loading && <Loader type={loading} />}
      {imageUrl && (
        <SendImage
          id={id}
          data={imageUrl}
          lowRes={lowRes}
          thumb={thumb}
          cancel={cancelImage}
          reselect={sendImg}
          caption={caption}
          onChange={userCaption}
          imgLink={(data) => sendImage(data)}
          loader={(data) => setLoading(data)}
          setProcess={(e) => {
            imageUrl && setProcess(e);
          }}
          process={process}
        />
      )}
      <div className="mythumb">
        <span>{state.sender}</span>
      </div>
      <div className="messageBox">
        {copyNotify && <Notify msg={"Message Copied to Clipboard"} />}
        {replyMsg && (
          <div className="replyMessage">
            {replyMsg[3] && (
              <div className="reply-image-thumb">
                {replyMsg[3] && (
                  <ProgressiveLazyImage
                    data={replyMsg[3]}
                    alt="replied thumb"
                  />
                )}
              </div>
            )}
            <div>
              <div className="replyMessage-thumb">{replyMsg[0]}</div>
              {
                <div className="replyMessage-msg">
                  {truncateString(replyMsg[1], window.innerWidth / 3)}
                </div>
              }
            </div>
            <span className="fas fa-times" onClick={closeReply}></span>
          </div>
        )}
        {/* <MessageArea /> */}
        <TextareaAutosize
          name="send_message"
          id="message"
          minRows={0}
          maxRows={6}
          autoFocus={true}
          placeholder="Message"
          onChange={userInput}
          value={input}
          maxLength={255}
          ref={msgTextArea}
          onFocus={focusMsg}
          onBlur={blurMsg}
        />
      </div>
      <div
        ref={sendMsgRef}
        className={`sendMsg ${!whiteSpace(input) ? "active" : ""}`}
      >
        <span class="material-symbols-rounded" onClick={sendImg}>
          add_a_photo
        </span>
        <span className="fas fa-paper-plane" onClick={sendMsg}></span>
      </div>
      {errMsg && <Notify msg={errMsg} type="error" />}
    </>
  );
}
