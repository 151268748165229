import { useEffect, useRef, useState } from "react";
import HomeHeader from "./homeHeader/homeHeader";
import "./homepage.scss";
import Inbox from "../../pages/inbox/inbox";
import Home from "../../pages/home/Home";
import Group from "../../pages/groups/group";
import Loader from "../loader/loader";
import { syncDashboard } from "../../utils";

import Footer from "../footer/footer";
export default function Homepage() {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState("home");
  const [user, setUser] = useState(false);
  const [newSeen, setNewSeen] = useState();

  useEffect(() => {
    setLoading(true);
    syncDashboard(setUser, setLoading);
  }, []);

  // console.log("====================================");
  // console.log(newSeen);
  // console.log("====================================");

  const thisPage = (e) => {
    setCurrentPage(e);
  };

  const scrollPage = {
    // transform: `translateX(${scroll})`
  };

  const homeContainer = useRef(null);

  return (
    <div className="homepage">
      <HomeHeader
        setThisPage={thisPage}
        containerRef={homeContainer}
        updateSeen={newSeen}
      />
      <div style={scrollPage} className="homeContainer" ref={homeContainer}>
        <Home user={user} page={currentPage} />
        <Inbox
          user={user}
          page={currentPage}
          updateSeen={(data) => setNewSeen(data)}
        />
        <Group user={user} page={currentPage} />
      </div>

      {/* {currentPage === 'home' ?  */}
      {/* <Home /> */}
      {/* : currentPage === 'inbox' ?  */}
      {/* <Inbox /> */}
      {/* : currentPage === 'groups' ?  */}
      {/* <Group /> */}
      {/* : <></>}   */}

      {loading && <Loader />}
    </div>
  );
}
