// MessageContext.js
import React, { createContext, useReducer, useContext } from 'react';

// Create a context with an initial state and a dispatch function
const MessageContext = createContext();

// Initial state
const initialState = { messages: [], sender: false};

// Reducer function
const messageReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SENDER':
      return { sender: action.payload, messages: state.messages};
    case 'ADD_MESSAGE':
      // console.log(state)
      return { messages: [...state.messages, action.payload], sender: state.sender };
    case 'INIT_MSG':
      return { messages: action.payload, sender: state.sender };
    default:
      return state;
  }
};

// Context provider component
const MessageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(messageReducer, initialState);

  return (
    <MessageContext.Provider value={{ state, dispatch }}>
      {children}
    </MessageContext.Provider>
  );
};

// Custom hook to simplify accessing the context
const useMessage = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessage must be used within a MessageProvider');
  }
  return context;
};

export { MessageProvider, useMessage };
