import Logo from "../logo/logo";
import "./loader.scss";
function Loader({ type }) {
  return (
    <div className="loader">
      {type === "spin" ? (
        <i class="fa-solid fa-circle-notch fa-spin"></i>
      ) : (
        <div className="wheel">
          <Logo />
        </div>
      )}
    </div>
  );
}
export default Loader;
