import { useState, useEffect } from "react";
import Card from "../../components/homepage/cards/card";
import "./inbox.scss";
import Notify from "../../components/notification/notify";
import InboxCard from "../../components/inboxCard/InboxCard";
import { shareAnnonLink, notAuthenticated } from "../../utils";
import { userMessages } from "../../services/api";
import Loader from "../../components/loader/loader";

export default function Inbox({ user, page, updateSeen }) {
  const [notify, isNotify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchMessages();
  }, [page]);

  const fetchMessages = async () => {
    try {
      let resp = await userMessages();
      if (resp.data) {
        setMessages(resp.data.data || []);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      notAuthenticated();
    }
  };

  const shareAnonnChat = () => {
    isNotify("Anonymous Chat Link Copied to Clipboard");
    shareAnnonLink(user?.username);
    setTimeout(() => {
      isNotify(false);
    }, 3000);
  };
  return (
    <>
      <div className="inboxPage">
        <div className="cards" onClick={shareAnonnChat}>
          <Card
            tabIndex={2}
            icon_name="fa-envelope"
            title="Start Anonymous Inbox"
            desc="Share a link to your friends to Inbox you Anonymously"
          />
        </div>
        <div className="myInbox">
          <div className="myInboxHead">My Inbox</div>
          <div className="myInboxContainer">
            {(messages.length > 0 &&
              messages?.map((msg, _) => (
                <div key={_} className="inboxCards" tabIndex={3}>
                  <InboxCard
                    id={msg.id}
                    message={msg.message}
                    seen={msg.seen}
                    title="Anonymous Message"
                    time={msg.date.split(" ")[1]}
                    day={msg.date.split(" ")[0]}
                    updateSeen={(data) => updateSeen(data)}
                  />
                </div>
              ))) || (
              <div className="cards no-room">
                Your Inbox is Empty! Share your Link to get more Message.
              </div>
            )}
          </div>
        </div>

        {notify && <Notify msg={notify} />}
        {loading && <Loader />}
      </div>
    </>
  );
}
