import { useCallback, useEffect, useState } from "react";
import "./InboxCard.scss";
import { currentUTCHour, truncateString } from "../../utils";
import { markMessageAsSeen } from "../../services/api";
import Capture from "../capture";

export default function InboxCard({
  id,
  message,
  title,
  day,
  time,
  seen,
  updateSeen,
}) {
  const [viewAll, setViewAll] = useState(false);
  const [status, setStatus] = useState(seen);
  const [capture, setCapture] = useState(false);

  const drop = async () => {
    if (!status) {
      setStatus(true);
      markMessageAsSeen(id);
      updateSeen(id);
    }
  };

  useEffect(() => {
    setStatus(seen);
  }, [seen]);

  const returnState = (data) => {
    setCapture(data);
  };

  const UTCtime = (e) => {
    const arr = e.split(":");
    return (
      currentUTCHour(arr[0]).toString().padStart(2, "0") +
      ":" +
      arr[1] +
      (arr[0] > 12 ? " PM" : " AM")
    );
  };
  return (
    <>
      {capture && (
        <Capture state={capture} returnState={returnState} message={message} />
      )}
      <div className="inbox_card_head">
        <div className="inbox_icon">
          <span className="fas fa-comments"></span>
        </div>
        <div className="inbox_type">
          <div className={`${status ? "inbox_title" : "unread"}`}>{title}</div>
          <div className={`${status ? "inbox_date" : "unread_date"}`}>
            <span>{UTCtime(time)}</span>
            <span> &bull; </span>
            <span>{day}</span>
          </div>
        </div>
        <div
          className="inbox-capture"
          onClick={() => {
            drop();
            setCapture(true);
          }}
        >
          <i class="fas fa-camera-retro"></i>
        </div>
        <div
          onClick={() => {
            drop();
            setViewAll(!viewAll);
          }}
          id="drop"
          className="drop"
        >
          {viewAll ? (
            <>
              <span className="rotate fas fa-caret-down"></span>
            </>
          ) : (
            <>
              <span className="fas fa-caret-down"></span>
            </>
          )}
        </div>
      </div>
      <div className="inbox_msg_container">
        {!viewAll ? (
          <>
            <label htmlFor="drop" className="half_inboxMsg">
              {message}
              {/* {truncateString(message, 40)} */}
            </label>
          </>
        ) : (
          <>
            <div className="full_inboxMsg">{message}</div>
          </>
        )}
      </div>
    </>
  );
}
