import ProgressiveLazyImage from "../components/ProgressiveImage";
import {
  dashboard,
  getMemberEmoji,
  uploadImage,
  validRoom,
} from "../services/api";
import emojiRegex from "emoji-regex";

export function validateEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export function copyText(text) {
  try {
    navigator.clipboard.writeText(text);
  } catch (error) {
    // alert(error)
  }
}

export function stringLength(string, len) {
  return string.length < len ? false : true;
}

export function checkEquals(first, second) {
  return first.length > 5 && second.length > 5 && first === second
    ? true
    : false;
}

export function notAuthenticated() {
  window.location.replace("/login");
}

export function authenticatedRoute() {
  window.location.replace("/homepage");
}
export function customRedirect(path) {
  window.location.replace(path);
}

export function saveUserCache(user) {
  setCookie("_cache_", JSON.stringify(user), 2);
}

export function fetchUserCache() {
  return JSON.parse(getCookie("_cache_"));
}

export async function syncDashboard(setUser, setLoading) {
  let __user = fetchUserCache();
  if (__user) {
    setUser(__user);
    setLoading(false);
  } else {
    try {
      let resp = await dashboard();
      if (resp.status) {
        setUser(resp.data);
        saveUserCache(resp.data);
        setLoading(false);
      }
    } catch (error) {
      notAuthenticated();
    }
  }
}

export function annonLink(username) {
  return `https://${window.location.host}/${username}`;
}

export function shareAnnonLink(username) {
  copyText(annonLink(username));
}

export function shareAppLink() {
  const link = `https://${window.location.host}`;
  copyText(link);
}

export function roomLink(id) {
  return `https://${window.location.host}/room/${id}`;
}

export function openRoom(room_id) {
  window.open(roomLink(room_id), "_blank");
}

export async function getEmoji(room_id) {
  let emoji = getCookie(room_id) || localStorage.getItem(room_id);
  if (emoji) {
    return emoji;
  } else {
    try {
      let resp = await getMemberEmoji(room_id);
      if (resp.status) {
        emoji = resp.data;
        localStorage.setItem(room_id, emoji);
        return emoji;
      }
    } catch (error) {
      window.location.replace("/roomfull");
      // console.log(error.response.data);
    }
  }
}

export const truncateString = (str, maxLength) => {
  try {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  } catch (error) {}
};

export function randomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const crypto = window.crypto || window.msCrypto; // For compatibility with IE11

  let result = "";
  const values = new Uint32Array(length);

  crypto.getRandomValues(values);

  for (let i = 0; i < length; i++) {
    const randomIndex = values[i] % characters.length;
    result += characters.charAt(randomIndex);
  }

  return result;
}

const getUserTimeZoneOffset = () => {
  var now = new Date();
  return now.getTimezoneOffset() / -60;
};

export function convertTo12HourFormat(time24) {
  var timeSplit = time24.split(":");
  var hours = parseInt(timeSplit[0]);
  var minutes = parseInt(timeSplit[1]);

  var date = new Date();
  date.setUTCHours(hours);
  date.setUTCMinutes(minutes);

  date.setHours(date.getHours());
  var period = date.getHours() >= 12 ? "PM" : "AM";

  hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
  hours = hours == 0 ? 12 : hours;

  minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

  return hours + ":" + minutes + " " + period;
}

function timeStringToMicroseconds(timeString) {
  var parts = timeString.split(":");
  var hours = parseInt(parts[0], 10) * 3600000000; // hours to microseconds
  var minutes = parseInt(parts[1], 10) * 60000000; // minutes to microseconds
  var seconds = parseInt(parts[2], 10) * 1000000; // seconds to microseconds
  var microseconds = parseInt(parts[3], 10);
  return hours + minutes + seconds + microseconds;
}

export const getTimeDifference = (time1, time2) => {
  var part1 = time1.split(":");
  var part2 = time2.split(":");

  // var microseconds1 = timeStringToMicroseconds(time1);
  // var microseconds2 = timeStringToMicroseconds(time2);
  // var timeDifference = Math.abs(microseconds1 - microseconds2);
  // return timeDifference / 100000000;
  const difference = Math.abs(part1[1] - part2[1]);
  return difference;
};

export const currentUTCHour = (hour) => {
  var date = new Date();

  date.setUTCHours(hour);
  date.setHours(date.getHours());
  return (hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours());
};

export const convertToCustomFormat = (dateTimeString) => {
  // Split the input into date and time parts
  var parts = dateTimeString.split(" ");
  var dateParts = parts[0].split("/");
  var timePart = parts[1].replace("[", "").replace("]", "");
  var meridiem = parts[2].replace("[", "").replace("]", "");
  // Extract date components
  var month = parseInt(dateParts[0], 10);
  var day = parseInt(dateParts[1], 10);
  var year = parseInt(dateParts[2], 10);

  // Convert hour to 24-hour format
  var timeComponents = timePart.split(":");
  var hour = parseInt(timeComponents[0], 10);
  var minute = parseInt(timeComponents[1], 10);
  hour = currentUTCHour(hour);

  // Array of short month names
  var shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var formattedDateTime =
    day +
    " " +
    shortMonthNames[month - 1] +
    " " +
    year +
    " - " +
    hour.toString().padStart(2, "0") +
    ":" +
    minute.toString().padStart(2, "0") +
    " " +
    meridiem;

  return formattedDateTime;
};

export const convertToCustomFormatThumb = (dateTimeString) => {
  // Split the input into date and time parts
  var parts = dateTimeString.split(" ");
  var dateParts = parts[0].split("/");
  var timePart = parts[1].replace("[", "").replace("]", "");
  var meridiem = parts[2].replace("[", "").replace("]", "");
  // Extract date components
  var month = parseInt(dateParts[0], 10);
  var day = parseInt(dateParts[1], 10);
  var year = parseInt(dateParts[2], 10);

  // Convert hour to 24-hour format
  var timeComponents = timePart.split(":");
  var hour = parseInt(timeComponents[0], 10);
  var minute = parseInt(timeComponents[1], 10);
  hour = currentUTCHour(hour);

  var formattedDateTime =
    day.toString().padStart(2, "0") +
    "/" +
    month.toString().padStart(2, "0") +
    "/" +
    year +
    " - " +
    hour.toString().padStart(2, "0") +
    ":" +
    minute.toString().padStart(2, "0") +
    " " +
    meridiem;

  return formattedDateTime;
};

export function setCookie(key, value, hours) {
  var expires = "";
  if (hours) {
    var date = new Date();
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = key + "=" + (value || "") + expires + "; path=/";
}

export function removeCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function clearAllCookies() {
  // Get all cookies
  const cookies = document.cookie.split(";");

  cookies.forEach((cookie) => {
    const [name] = cookie.split("=");
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  });
}

export function getCookie(key) {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();
    if (cookie.startsWith(key + "=")) {
      return cookie.substring(key.length + 1);
    }
  }
  // If the cookie with the specified key is not found, return null
  return null;
}
export const getCurrentUTCTime = () => {
  const now = new Date();
  const hours = now.getUTCHours().toString().padStart(2, "0");
  const minutes = now.getUTCMinutes().toString().padStart(2, "0");
  const seconds = now.getUTCSeconds().toString().padStart(2, "0");
  const milliseconds = now.getUTCMilliseconds().toString().padStart(3, "0");
  const microseconds = milliseconds.padEnd(6, "0"); // Pad to microseconds

  return `${hours}:${minutes}:${seconds}:${microseconds}`;
};

export function replaceLineBreaksWithSpace(text) {
  return text.replace(/(\r\n|\r|\n)/g, " ");
}

export const isMobileBrowser = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export function AutoHyperlink({ text }) {
  const escapedText = text.replace(/<(\/?)(?!a\b)([^>]+)>/g, "&lt;$1$2&gt;");

  // Regular expression to match URLs not within HTML tags
  const urlRegex = /(?<!<)(https?:\/\/[^\s]+)(?!>)/g;

  // Replace URLs with <a> elements
  const parsedContent = escapedText.replace(urlRegex, (url, index) => {
    return `<a class='message-link' key=${index} href=${url} target="_blank" rel="noopener noreferrer">${url}</a>`;
  });

  // Convert the parsed content string to JSX
  const jsxContent = (
    <div dangerouslySetInnerHTML={{ __html: parsedContent }} />
  );

  return jsxContent;
}
export const clusterCheck = (state, i) => {
  return (
    state?.messages[i + 1] &&
    state?.messages[i - 1] &&
    state?.messages[i].sender === state?.messages[i + 1].sender &&
    state?.messages[i].sender === state?.messages[i - 1].sender
  );
};

export const singleClusterCheck = (state, i) => {
  return state?.messages[i + 1] && state?.messages[i - 1]
    ? state?.messages[i].sender !== state?.messages[i - 1].sender &&
        state?.messages[i].sender !== state?.messages[i + 1].sender
    : state?.messages[i - 1]
    ? state?.messages[i].sender !== state?.messages[i - 1].sender
    : state?.messages[i + 1]
    ? state?.messages[i].sender !== state?.messages[i + 1].sender
    : !state?.messages[i + 1] && !state?.messages[i - 1] && true;
};

export const endClusterCheck = (state, i) => {
  return state?.messages[i + 1]
    ? state?.messages[i].sender !== state?.messages[i + 1].sender && i !== 0
    : true;
};

export const startClusterCheck = (state, i) => {
  return state?.messages[i - 1]
    ? state?.messages[i].sender !== state?.messages[i - 1].sender
    : true;
};

const getAllGroups = () => {
  const groups = [];
  Object.entries(localStorage).forEach(([key, value]) => {
    if (isUUID(key)) {
      groups.push(key);
    }
  });
  return groups;
};

export const clearAllInvalidGroups = async () => {
  const groups = getAllGroups();
  groups.forEach(async (id) => {
    const resp = await validRoom(id);
    if (!resp) {
      localStorage.removeItem(id);
    }
  });
};

export function isUUID(str) {
  const uuidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return uuidPattern.test(str);
}

export const compressImage = (file, max) => {
  return new Promise((resolve, reject) => {
    if (file.size <= 512000 && max >= 1080) {
      const originalUrl = URL.createObjectURL(file);
      resolve(originalUrl);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const maxWidthOrHeight = max !== undefined ? max : 1080; // Maximum width or height of the output image
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidthOrHeight) {
            height *= maxWidthOrHeight / width;
            width = maxWidthOrHeight;
          }
        } else {
          if (height > maxWidthOrHeight) {
            width *= maxWidthOrHeight / height;
            height = maxWidthOrHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File([blob], file.name, {
              type: "image/jpeg",
            });
            const compressedUrl = URL.createObjectURL(compressedFile);
            resolve(compressedUrl);
          },
          "image/jpeg",
          0.5
        );
      };

      img.src = event.target.result;
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
export function downloadImage(url, filename) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl); // Clean up the object URL
    })
    .catch((error) => console.error("Download failed:", error));
}

export const replyMessage = (
  thumb,
  msg,
  caption,
  imageSrc,
  parentNode,
  containerRef,
  setReply
) => {
  if ("vibrate" in navigator) {
    navigator.vibrate(50);
  }
  if (parentNode) {
    var index = Array.prototype.indexOf.call(
      parentNode.children,
      containerRef.current
    );
    setReply([
      thumb,
      msg ? msg : imageSrc && caption ? caption : "Photo",
      index,
      imageSrc ? imageSrc : "",
    ]);
  }
};

export const RepliedCard = ({ repliedMsg, showReplied }) => {
  return (
    <div className="replied" onClick={showReplied}>
      {repliedMsg[3] && (
        <div className="reply-image-thumb">
          {repliedMsg[3] && (
            <ProgressiveLazyImage alt="replied thumb" data={repliedMsg[3]} />
          )}
        </div>
      )}
      <div>
        <div className="replied-thumb">{repliedMsg[0]}</div>
        <div className="replied-msg">{repliedMsg[1]}</div>
      </div>
    </div>
  );
};

export const ClusterId = (cluster, singleCluster, endCluster, startCluster) => {
  return cluster
    ? "cluster"
    : singleCluster
    ? "singleCluster"
    : endCluster
    ? "endCluster"
    : startCluster
    ? "startCluster"
    : "";
};
export const PlaySound = () => {
  try {
    const messageSound = new Audio(
      process.env.PUBLIC_URL + "/sounds/pop-alert.mp3"
    );
    messageSound.play();
  } catch (error) {
    console.log(error);
  }
};

export const ResetFields = (
  setInput,
  setRepliedMsg,
  setReplyMsg,
  setCaption
) => {
  setInput("");
  setCaption("");
  setRepliedMsg("");
  setReplyMsg("");
  const x = document.querySelectorAll(".messageContainer")[0];
  setTimeout(() => {
    if (x) {
      x.lastChild.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, 1);
};

export function imageUrlToBase64(imageUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = imageUrl;
  });
}

export function downloadImageFromImgTag(imgElement) {
  if (imgElement !== null) {
    try {
      const imgSrc = imgElement.src;
      const imgName = imgSrc.substring(imgSrc.lastIndexOf("/") + 1);

      const link = document.createElement("a");
      link.href = imgSrc;
      link.download = imgName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  }
}

export const getImgLink = async ({ formData, setErr, imgLink }) => {
  const resp = await uploadImage(formData);
  if (resp.status === 200) {
    imgLink(resp.data.message);
  } else {
    setErr("Failed to send image");
    setTimeout(() => {
      setErr(false);
    }, 4000);
  }
};

export function isEmojiOnly(text) {
  const regex = emojiRegex();
  const trimmedText = text.trim();

  const matches = trimmedText.match(regex) || [];

  return matches.join("") === trimmedText;
}

export function isSingleEmoji(text) {
  const regex = emojiRegex();
  const trimmedText = text.trim();

  const matches = trimmedText.match(regex) || [];

  return matches.length === 1 && matches.join("") === trimmedText;
}

// export const areImagesLoaded = () => {
//   const images = Array.from(document.images);
//   return images.every((image) => image.complete);
// };

export const findIndexOfTag = (tagName) => {
  // Get all elements with the specified tag name
  const elements = document.getElementsByTagName(tagName);

  // Loop through the HTMLCollection to find the index of the element
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].tagName.toLowerCase() === tagName.toLowerCase()) {
      return i; // Return the index of the element with the tag name
    }
  }

  return -1; // Return -1 if the tag name is not found
};
