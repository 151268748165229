import "./auth.scss";
import Logo from "../logo/logo";
import Message from "../message/Message";
import { useState } from "react";
import { Link } from "react-router-dom";
import { registerUser } from "../../services/api";
import { validateEmail, stringLength } from "../../utils";
import Loader from "../loader/loader";
import { notify } from "../notification/notify";
import { useNavigate } from "react-router-dom";

function Signup({ title }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uname, setUname] = useState("");
  const [hasMsg, setHasMsg] = useState({
    visibility: false,
    message: null,
    error: null,
  });
  const [registerForm, setRegisterForm] = useState({
    username: "",
    email: "",
    password: "",
  });

  const msgState = () => {
    setHasMsg(!hasMsg);
  };

  const formDataKeyUp = (key, value) => {
    setRegisterForm({ ...registerForm, [key]: value });
  };

  const createUser = async () => {
    try {
      if (!validateEmail(registerForm.email)) {
        setHasMsg({
          visibility: true,
          message: "Invalid Email Address",
          error: true,
        });
      } else if (!stringLength(registerForm.username, 6)) {
        setHasMsg({
          visibility: true,
          message: "Username must be 6 characters or above",
          error: true,
        });
      } else if (!stringLength(registerForm.password, 8)) {
        setHasMsg({
          visibility: true,
          message: "Password must be 8 characters or above",
          error: true,
        });
      } else {
        setLoading(true);
        let resp = await registerUser(registerForm);
        if (resp.status) {
          console.log(resp);
          setHasMsg({
            visibility: true,
            message: "User Account Created",
            error: false,
          });
          setLoading(false);
          navigate("/login")
          notify("Welcome", "AnonnChat Account Created Succefully");
        }
      }
    } catch (error) {
      setHasMsg({
        visibility: true,
        message: error.response.data.message,
        error: true,
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="box_container">
      <div className="logo">
        <Logo />
      </div>
      <div className="title">{title ? title : "Create Account"}</div>
      {hasMsg.visibility && (
        <Message
          err={hasMsg.message}
          color={hasMsg.error ? "#F02A50" : "#49D87E"}
          msgState={msgState}
        />
      )}

      <div className="form">
        <div className="username">
          <label htmlFor="username">Username</label>
          <input
            onKeyUp={(e) => {
              formDataKeyUp("username", e.target.value);
            }}
            type="text"
            name="username"
            id="username"
            value={uname}
            maxLength={16}
            onChange={(e) => {
              setUname(
                e.target.value.replace(/\s+/g, function (match) {
                  return uname[uname.length - 1] !== "-"
                    ? match.length > 0
                      ? "-"
                      : " "
                    : "";
                })
              );
            }}
          />
        </div>
        <div className="email">
          <label htmlFor="email">Email address</label>
          <input
            onKeyUp={(e) => {
              formDataKeyUp("email", e.target.value);
            }}
            type="email"
            name="email"
            id="email"
          />
        </div>
        <div className="password">
          <label htmlFor="password">Password</label>
          <input
            onKeyUp={(e) => {
              formDataKeyUp("password", e.target.value);
            }}
            type="password"
            name="username"
            id="password"
          />
        </div>
        <label className="terms">
          <span className="check_container">
            {" "}
            <input type="checkbox" name="" id="terms" checked />
          </span>
          <label htmlFor="terms">
            By signing up you agree to our{" "}
            <Link to="../terms" className="check">
              Terms and Conditions
            </Link>{" "}
          </label>
        </label>
        <div onClick={createUser} className="button">
          Sign up
        </div>
      </div>
      <div className="options">
        Already have an Account?{" "}
        <Link to="/login">
          {" "}
          <span className="link">Login</span>{" "}
        </Link>
      </div>
      {loading && <Loader />}
    </div>
  );
}
export default Signup;
