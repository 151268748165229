function Message({ err, color, msgState }) {
  const style = {
    color: color,
  };
  const close = (e) => {
    e.target.parentElement.parentElement.style.display = "none";
    msgState(false);
  };
  return (
    <div className="error_message">
      <div style={style} className="err">
        {err}
      </div>
      <div className="close">
        <span onClick={close} className="fas fa-times"></span>
      </div>
    </div>
  );
}

export default Message;
