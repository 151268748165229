import { useMemo, useRef, useState } from "react";
import Logo from "../../components/logo/logo";
import "./index.scss";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
export default function Index() {
  const index = useRef(null);
  const [lightMode, setLightMode] = useState();
  const [drop, setDrop] = useState(false);
  const [dropValue, setDropValue] = useState("Connect");
  const dropArray = useMemo(
    () => ["Connect", "Inbox", "Capture", "AnonnHubs", "Privacy"],
    []
  );
  const tabs = [
    {
      icon: "fa-user-secret",
      title: "Anonn Connections",
      special: "Await You",
      body: "Connect authentically while safeguarding your identity. Our secure and welcoming space invites you to build genuine connections with others, sharing thoughts and ideas without inhibition.",
    },
    {
      icon: "fa-envelope",
      last: " No Strings Attached",
      special: "Secret Chats,",
      body: "Dive into the world of AnonBox, where you can connect privately without revealing your identity. Share your thoughts, secrets, and laughter in a judgment-free environment designed for genuine connections.",
    },
    {
      icon: "fa-camera",
      special: "Capture Moments ",
      last: " and Share Anonymously",
      body: "Experience the magic of AnonnChat as you cherish and share your most memorable conversations as images. AnonnChat allows you to capture and revisit meaningful discussions, preserving the essence of your connections in a visual format.",
    },
    {
      icon: "fa-users",
      title: "Join Global",
      second: "AnonnHubs,",
      special: "Be Yourself",
      body: "Embrace the power of anonymity in diverse group chats with AnonnHubs. Connect with like-minded voices from around the world, fostering a unique community of open conversations where everyone is encouraged to be their authentic selves.",
    },
    {
      icon: "fa-shield",
      title: "Express Freely and",
      special: "Guard Your Identity",
      body: " At AnonnChat, we prioritize your privacy. Feel the freedom to express yourself openly and authentically in a secure space built on trust and respect. Your identity remains protected, allowing you to engage in conversations without hesitation.",
    },
  ];
  const sliders = [
    {
      tab: "/slider/slider_tab_1.png",
      phone: "/slider/slider_mobile_1.png",
    },
    {
      tab: "/slider/slider_tab_2.png",
      phone: "/slider/slider_mobile_2.png",
    },
    {
      tab: "/slider/slider_tab_3.png",
      phone: "/slider/slider_mobile_3.png",
    },
    {
      tab: "/slider/slider_tab_1.png",
      phone: "/slider/slider_mobile_1.png",
    },
    {
      tab: "/slider/slider_tab_4.png",
      phone: "/slider/slider_mobile_4.png",
    },
  ];

  const [next, setNext] = useState(0);
  const imageContainer = useRef(null);
  const container = useRef(null);
  const select = document.querySelectorAll(".index-options-tab");
  useEffect(() => {
    if (select) {
      select.forEach((tabs, i) => {
        tabs.addEventListener("click", (e) => {
          setDropValue(dropArray[i]);
          setNext(i);
        });
      });
    }
  }, [dropArray, select, next]);
  let modeIcon = lightMode ? "dark_mode" : "light_mode";

  const setnext = (data) => {
    if (data + 1 === next) {
      setNext(next);
      container.current.children[next].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    } else {
      setNext(data + 1);
    }
  };

  useEffect(() => {
    container.current.children[next].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [next]);

  useEffect(() => {
    const clean = container.current;
    const slide = imageContainer.current;
    slide.children[0].classList.add("show-phone");
    const handleScroll = () => {
      setTimeout(() => {
        if (slide && clean) {
          let precise =
            Math.round(clean.scrollLeft / clean.clientWidth) %
            clean.clientWidth;
          setDropValue(dropArray[precise]);
          const siblings = Array.from(slide.children).filter(
            (child) => child !== slide.children[precise]
          );

          slide.children[precise].classList.add("show-phone");
          siblings.forEach((sibling) => {
            sibling.classList.remove("show-phone");
          });
        }
      }, 1000);
    };

    if (clean) {
      clean.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (clean) {
        clean.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const storedMode = JSON.parse(sessionStorage.getItem("lightMode"));
    if (storedMode === null) {
      sessionStorage.setItem("lightMode", JSON.stringify(true));
      setLightMode(storedMode);
    } else {
      setLightMode(storedMode);
    }
    if (!storedMode && index.current) {
      index.current.classList.add("darkmode");
    }
  }, []);

  const toggleMode = () => {
    setLightMode(!lightMode);
    sessionStorage.setItem("lightMode", !lightMode);
    index.current.classList.toggle("darkmode");
  };

  // useEffect(() => {
  //   console.log(lightMode);
  // }, [lightMode]);

  return (
    <div className="index-container" ref={index} onClick={() => setDrop(false)}>
      <div className="index-header">
        <Logo />
        <div className="index-header-title">
          Connect Anonymously with AnonnChat
        </div>
        <div
          className="index-select"
          onClick={(e) => {
            e.stopPropagation();
            drop ? setDrop(false) : setDrop(true);
          }}
        >
          <span>{dropValue}</span>
          <i className={`fa-solid fa-angle-down ${drop && "rotate"}`}></i>
        </div>
        <div className={`index-options ${drop && "drop-index"}`}>
          {dropArray.map((data, i) => (
            <div className="index-options-tab">{data}</div>
          ))}
        </div>
      </div>
      <div className="index-body" ref={container}>
        {tabs.map((tab, i) => (
          <TabCard
            icon={tab.icon}
            title={tab.title}
            special={tab.special}
            second={tab.second}
            last={tab.last}
            body={tab.body}
            next={i}
            setNext={setnext}
          />
        ))}
      </div>
      <div className="index-image" ref={imageContainer}>
        {sliders.map((data, i) => (
          <Screenshot tab={data.tab} phone={data.phone} />
        ))}
      </div>
      <div className="index-footer">
        <span class="material-symbols-outlined">link</span>
        <Link to={"/signup"}>
          <span>Create Account</span>
        </Link>
        <Link to={"/login"}>
          <span>Login</span>
        </Link>
        <Link to={"/terms"}>
          <span>Terms</span>
        </Link>
        <Link to={"/privacy"}>
          <span>Privacy</span>
        </Link>
      </div>

      <div id="toggle-mode" onClick={toggleMode}>
        <span class="material-symbols-outlined">{modeIcon}</span>
      </div>
    </div>
  );
}

export function TabCard({
  icon,
  second,
  title,
  special,
  body,
  next,
  setNext,
  last,
}) {
  const [tab, inView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  return (
    <div className={`tab ${inView && "slide"}`} ref={tab}>
      <div className="tab-icon">
        <i className={`fa-solid ${icon}`}></i>
      </div>
      <div className="tab-title">
        {title} {second && <span>{second}</span>} <span>{special}</span>
        <span>{last}</span>
      </div>
      <div className="tab-body">{body}</div>
      <div className="tab-btn">
        {(next === 4 && (
          <Link to={"/login"}>
            <div
              className={`tab-button ${next === 4 && "getStarted"}`}
              onClick={() => (next < 4 ? setNext(next) : setNext(3))}
            ></div>
          </Link>
        )) || (
          <div
            className={`tab-button ${next === 4 && "getStarted"}`}
            onClick={() => (next < 4 ? setNext(next) : setNext(3))}
          ></div>
        )}
      </div>
    </div>
  );
}

export function Screenshot({ tab, phone, index }) {
  return (
    <div className="image-first" id="screenshot">
      <img alt="AnonnChat" src={process.env.PUBLIC_URL + tab}></img>
      <img alt="AnonnChat" src={process.env.PUBLIC_URL + phone}></img>
    </div>
  );
}
