import "./auth.scss";
import Logo from "../logo/logo";
import Message from "../message/Message";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";
import { loginUser, setAccessToken } from "../../services/api";
import { stringLength, authenticatedRoute } from "../../utils";

function Login({ auth }) {
  const [hasMsg, setHasMsg] = useState({
    visibility: false,
    message: null,
    error: null,
  });
  const [loading, setLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    email_or_username: "",
    password: "",
  });
  const [uname, setUname] = useState("");

  const msgState = () => {
    setHasMsg(!hasMsg);
  };

  const formDataKeyUp = (key, value) => {
    setLoginForm({ ...loginForm, [key]: value });
  };

  const Login = async () => {
    if (
      !stringLength(loginForm.email_or_username, 6) ||
      !stringLength(loginForm.password, 6)
    ) {
      setHasMsg({
        visibility: true,
        message: "Kindly input valid login credentials",
        error: true,
      });
    } else {
      try {
        setLoading(true);
        let resp = await loginUser(loginForm);
        if (resp.status) {
          setHasMsg({
            visibility: true,
            message: "User Logged In",
            error: false,
          });
          setAccessToken(resp.data.access_token);
          authenticatedRoute();
          setLoading(false);
        }
      } catch (error) {
        setHasMsg({
          visibility: true,
          message: error.response.data.message,
          error: true,
        });
        setLoading(false);
      }
    }
  };

  return (
    <div className="box_container">
      <div className="logo">
        <Logo />
      </div>
      <div className="title">Sign in to AnonnChat</div>
      {hasMsg.visibility && (
        <Message
          err={hasMsg.message}
          color={hasMsg.error ? "#F02A50" : "#49D87E"}
          msgState={msgState}
        />
      )}

      <div className="form">
        <div className="username">
          <label htmlFor="username">Username or email address</label>
          <input
            tabIndex={1}
            type="text"
            name="username"
            id="username"
            title="username"
            value={uname}
            onChange={(e) => {
              formDataKeyUp("email_or_username", e.target.value);
              setUname(
                e.target.value.replace(/\s+/g, function (match) {
                  return uname[uname.length - 1] !== "-"
                    ? match.length > 0
                      ? "-"
                      : " "
                    : "";
                })
              );
            }}
          />
        </div>
        <div className="password">
          <label htmlFor="password">Password</label>
          <Link to="/reset">
            <span className="link forgot" tabIndex={2}>
              Forgot Password?
            </span>
          </Link>
          <input
            tabIndex={1}
            onChange={(e) => {
              formDataKeyUp("password", e.target.value);
            }}
            type="password"
            title="password"
            name="username"
            id="password"
          />
        </div>
        <div className="button" onClick={Login}>
          Sign in
        </div>
      </div>
      <div className="options">
        New to AnonnChat?{" "}
        <Link to="/signup">
          <span className="link">Create an account</span>{" "}
        </Link>
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default Login;
