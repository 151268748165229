import "./card.scss";
import { deleteRoom, getRoom } from "../../../services/api";
import { roomLink } from "../../../utils";

export default function Card({ icon_name, title, desc, group, id }) {
  const deleteGroup = async (event) => {
    const parent = event.target.offsetParent.offsetParent;
    try {
      let resp = await deleteRoom(id);
      console.log(resp);
      if (resp) {
        parent.style.display = "none";
      }
    } catch (error) {
      console.log(error);
    }
  };

  const shareGroup = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Anonnchat Group Link",
          text: `Follow this link to join my anonymous group chat: ${title}`,
          url: roomLink(id),
        });
        console.log("Content shared successfully");
      } else {
        console.log("Web Share API not supported in this browser");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  return (
    <>
      <div className="card_icon">
        <span className={`icon fas ${icon_name}`}></span>
      </div>
      <div className="card_title">{title}</div>
      <div className="card_desc">{desc}</div>
      {group && (
        <div className="card_options" onClick={(e) => e.stopPropagation()}>
          <span class="material-symbols-rounded" onClick={shareGroup}>
            share
          </span>
          <span class="material-symbols-rounded" onClick={deleteGroup}>
            delete
          </span>
        </div>
      )}
    </>
  );
}
