import React, { useState } from "react";
import { truncateString } from "../../utils";
import Logo from "../logo/logo";
import "./chathead.scss";

export default function ChatHead({
  groupName,
  members,
  creator,
  getActiveUsers,
}) {
  const [showMembers, setShowMembers] = useState(false);
  const MatchActiveMember = ({ index, member }) => {
    const memberIsActive = members.active.indexOf(member) >= 0 ? true : false;
    return (
      <div key={index} className="member">
        <span>{member}</span>
        {memberIsActive && <span></span>}
      </div>
    );
  };

  const SeeMembers = () => {
    getActiveUsers();
    setShowMembers(!showMembers);
  };
  return (
    <div className="chatHead">
      {/* <div className="navbar">
            <Nav />
          </div> */}
      <div className="chat_logo">
        <Logo />
      </div>
      <div className="delete">
        {/* <span className="fas fa-trash-alt"></span> */}
      </div>
      <div className="roomName" title={groupName ? groupName : ""}>
        {truncateString(groupName, 20)}
      </div>
      {/* <div className="chatHead-icon">
        <i className="fa-solid fa-user-secret"></i>
      </div> */}
      {members.all.length > 1 && (
        <span
          class={`material-symbols-rounded ${showMembers ? "up" : "down"}`}
          id={"dropdown"}
          onClick={SeeMembers}
        >
          keyboard_arrow_down
        </span>
      )}
      <label onClick={SeeMembers} className="members-thumb">
        {members.all.map((member, index) => {
          return (
            (member === creator
              ? member !== creator && index < 3
              : index < 4) && (
              <div key={index} className="thumb">
                <span>{member}</span>
              </div>
            )
          );
        })}
        {/* {members.active.length > 1 && <span></span>} */}
      </label>
      <div className="members-list" id={showMembers ? "show" : "hide"}>
        {members.all.map((member, index) => {
          return <MatchActiveMember index={index} member={member} />;
        })}
      </div>
    </div>
  );
}
