import { useState } from "react";
import Notify from "../../components/notification/notify";
import Card from "../../components/homepage/cards/card";
import "./home.scss";
import { shareAppLink, shareAnnonLink, annonLink } from "../../utils/index";
import { Link } from "react-router-dom";
export default function Home({ user }) {
  const [notify, isNotify] = useState(false);

  const shareApp = () => {
    isNotify("Link Copied to Clipboard");
    shareAppLink();
    setTimeout(() => {
      isNotify(false);
    }, 3000);
  };
  const shareAnonnChat = () => {
    isNotify("Anonymous Chat Link Copied to Clipboard");
    shareAnnonLink(user?.username);
    setTimeout(() => {
      isNotify(false);
    }, 3000);
  };
  const whatsharedText = `Drop an *anonymous note* and keep me guessing! 🤫📝 ${encodeURIComponent(
    annonLink(user?.username)
  )}`;
  const tweetsharedText = `Drop an anonymous note and keep me guessing! 🤫📝 #AnonnChat`;

  return (
    <div className="homePage">
      <Link to="/room/new" tabIndex={2}>
        <div className="cards">
          <Card
            icon_name="fa-users"
            title="Create Group chat"
            desc="Share a link for your Anonymous Group chat"
          />
        </div>
      </Link>
      <div className="cards" onClick={shareAnonnChat} tabIndex={2}>
        <Card
          icon_name="fa-user-lock"
          title="Start Anonymous Inbox"
          desc="Share a link to your friends to Inbox you Anonymously"
        />
      </div>
      <div onClick={shareApp} className="cards" tabIndex={2}>
        <Card
          icon_name="fa-link"
          title="Invite Your Friends"
          desc="Share link to your friends to enjoy this app too 😜"
        />
      </div>
      <div className="share">
        <a
          className="whatsapp"
          href={`whatsapp://send?text=${whatsharedText}&data-action=share/whatsapp/share`}
        >
          <span className="fab fa-whatsapp"></span>
        </a>
        <a
          className="facebook"
          href={
            "fb-messenger://share/?link=" +
            encodeURIComponent(annonLink(user?.username))
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="fab fa-facebook-messenger"></span>
        </a>
        <a
          className="twitter"
          href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
            annonLink(user?.username)
          )}&text=${tweetsharedText}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="fab fa-twitter"></span>
        </a>
      </div>
      {notify ? <Notify msg={notify} /> : <></>}
    </div>
  );
}
