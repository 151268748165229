import { useEffect } from "react";
import { IndexHeader } from "../../components/IndexHeader";
import { LandingFooter } from "../landing";
import "./about.scss";

export function AboutUs() {
  const aboutAnonnChat = [
    {
      title: "Our App",
      content:
        "AnonnChat fosters authentic connections through anonymous communication, providing a safe space for individuals to express themselves freely without fear of judgment or exposure. Our platform is dedicated to fostering genuine interactions and meaningful conversations.",
    },
    {
      title: "Our Vision",
      content:
        "Our vision for AnonnChat is to empower users to share thoughts, feelings, and experiences openly, creating a supportive community where every voice is heard and respected. We believe in the power of anonymity to break down barriers and facilitate genuine connections.",
    },
    {
      title: "Privacy and Security",
      content:
        "At AnonnChat, privacy and security are paramount. We employ industry-leading encryption and privacy measures to protect users' identities and personal information, ensuring utmost confidentiality and discretion.",
    },
    {
      title: "Join the Community",
      content:
        "Join the AnonnChat community to connect with like-minded individuals, seek advice, or share your story anonymously. Experience the freedom to express yourself authentically and engage in meaningful conversations.",
    },
  ];
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <>
      <IndexHeader />
      <div className="about">
        <div className="about-title">About Anonnchat</div>
        {aboutAnonnChat.map((data) => (
          <AboutCard head={data.title} body={data.content} />
        ))}
      </div>
      <LandingFooter />
    </>
  );
}

export const AboutCard = (props) => {
  return (
    <div className="about-card">
      <div className="about-head">{props.head}</div>
      <div className="about-body">{props.body}</div>
    </div>
  );
};
