import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <Link to="/privacy">
        <span className="link">Privacy</span>{" "}
      </Link>
      <Link to="/terms">
        <span className="link">Terms</span>{" "}
      </Link>
      <Link to="/feedback">
        <span className="link">Feedback</span>{" "}
      </Link>
    </div>
  );
}

export default Footer;
