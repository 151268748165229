import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../../components/logo/logo";
import Message from "../../components/message/Message";
import "./Compose.scss";
import Signup from "../../components/auth/signup";
import Loader from "../../components/loader/loader";
import { verifyUsername, sendAnnonMessage } from "../../services/api";
import { stringLength, customRedirect } from "../../utils";
import { Helmet } from "react-helmet";

export default function Compose() {
  const { username } = useParams();
  const [loading, setLoading] = useState(false);
  const [hasMsg, setHasMsg] = useState(false);
  const [composeMsg, setComposeMsg] = useState("");
  const [msg, setMsg] = useState("");
  const [msgColor, setMsgColor] = useState("");
  const [hasSent, setHasSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState(false);
  const title = `Chat anonymously with ${username} on AnonnChat! 🤐🤫`;

  useEffect(() => {
    setLoading(true);
    verifyUserLink();
  }, []);

  const verifyUserLink = async () => {
    const id = await verifyUsername(username);
    setUserId(id);
    setLoading(false);
  };

  const msgState = () => {
    setHasMsg(!hasMsg);
  };

  const compose = async () => {
    if (!stringLength(composeMsg, 10)) {
      setMsg("Please Lengthen your message to more than 10 Characters");
      setMsgColor("red");
      setHasMsg(true);
    } else {
      setIsLoading(true);
      try {
        let resp = await sendAnnonMessage({ id: userId, message: composeMsg });

        if (resp.status) {
          setTimeout(() => {
            setMsg(`Anonymous Message Sent to ${username}`);
            setHasMsg(true);
            setMsgColor("green");
            setComposeMsg("");
            setIsLoading(false);
          }, 3100);
          setTimeout(() => {
            setHasSent(true);
          }, 4000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const userInput = (e) => {
    if (e.target.value.length <= 280 || e.target.scrollHeight < 150) {
      setComposeMsg(e.target.value);
    }
  };

  const close_pop_up = (e) => {
    e.target.parentElement.parentElement.classList = "works hide_pop";
    setTimeout(() => {
      e.target.parentElement.parentElement.parentElement.style.visibility =
        "hidden";
    }, 350);
  };
  return (
    <>
      <Helmet>
        <meta property="og:title" content={title} />
        <title>{`Message  ${username} || AnonnChat`}</title>
      </Helmet>
      {!hasSent ? (
        <>
          <div className="compose">
            <div className="compose_logo">
              <Logo />
            </div>
            <div className="compose_title">
              Send an Anonymous Message to <strong>{username}</strong>
            </div>
            {hasMsg && (
              <Message err={msg} color={msgColor} msgState={msgState} />
            )}
            <div className="compose_container">
              <textarea
                placeholder={`Write a message to ${username}`}
                id="composeText"
                rows={7}
                onChange={userInput}
                value={composeMsg}
              />
              <div className="button" onClick={compose}>
                Send Message
              </div>
            </div>
          </div>
          {isLoading && <Loader />}
        </>
      ) : (
        <>
          <div className="workContainer">
            <div className="works">
              <div className="head">
                <div className="pop_head">How It Works</div>
                <span onClick={close_pop_up} className="fas fa-times"></span>
              </div>
              <div className="work_lists">
                <div>Create Account Now!</div>
                <div>Share Your Anon Inbox link with your friends</div>
                <div>
                  Receive an Anonymous message from your friends and others{" "}
                </div>
              </div>
            </div>
          </div>
          <Signup title={`Now It's Your Turn 😜`} />
        </>
      )}
      {loading && <Loader />}
    </>
  );
}
