import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Card from "../../components/homepage/cards/card";
import Nav from "../privacy/nav";
import "./settings.scss";
import Loader from "../../components/loader/loader";
import { logOut, deleteUser, chengePassword } from "../../services/api";
import { checkEquals, stringLength, syncDashboard } from "../../utils";
import Notify from "../../components/notification/notify";
import Message from "../../components/message/Message";

export default function Settings() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [resetState, setResetState] = useState(false);
  const [logout, setLogout] = useState(false);

  const closeConfirm = () => {
    setConfirm(false);
    setResetState(false);
  };
  const closeLogout = () => {
    setLogout(false);
  };
  // const inputPwd = (e) => {
  //   setPwd(e.target.value);
  // };
  useEffect(() => {
    setLoading(true);
    syncDashboard(setUser, setLoading);
  }, []);

  return (
    <div className="settings">
      <div className="settingsHead">
        <Nav />
        <div className="settingsTitle">Settings</div>
      </div>
      <div className="settingsContainer">
        <div className="cards">
          <Card
            icon_name="fa-id-card"
            title={user?.username}
            desc={user?.email}
          />
        </div>
        <Link to="/privacy" className="cards">
          <Card
            icon_name="fa-user-lock"
            title="Privacy"
            desc="Explore Anonnchat's privacy and policy."
          />
        </Link>
        <div className="cards" onClick={() => setResetState(true)}>
          <Card
            icon_name="fa-vault"
            title="Change Password"
            desc="Tap to change your password from the existing one."
          />
        </div>
        <div className="cards delete_acc" onClick={() => setConfirm(true)}>
          <Card
            icon_name="fa-trash-alt"
            title="Delete Account"
            desc="Your account will be Permernently removed."
          />
        </div>
        <div className="cards" onClick={() => setLogout(true)}>
          <Card
            icon_name="fa-sign-out"
            title="Log Out"
            desc="You can always Login again anytime."
          />
        </div>
      </div>
      {confirm && <DeleteAccount closeConfirm={closeConfirm} />}
      {resetState && (
        <ChangePwd
          closeConfirm={closeConfirm}
          // updatePwd={}
        />
      )}
      {logout && (
        <div className="confirm-delete-container" onClick={closeLogout}>
          <div
            className="confirm-delete for-logout"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="confirm-delete-confirm">
              Are you sure you want to logout
            </div>
            <div className="confirm-delete-btn">
              <div onClick={closeLogout}>Cancel</div>
              <div className="confirm-logout" onClick={logOut}>
                Logout
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
}

export const DeleteAccount = ({ closeConfirm }) => {
  const [pwd, setPwd] = useState("");
  const [err, setErr] = useState(false);

  const deleteFunc = () => {
    const req = deleteUser(pwd);
    if (req) {
      (async () => {
        const data = await req;
        setErr(data);
      })();
    }
  };
  return (
    <div
      className="confirm-delete-container"
      onClick={(e) => {
        setPwd("");
        closeConfirm();
      }}
    >
      <div className="confirm-delete" onClick={(e) => e.stopPropagation()}>
        <div className="confirm-delete-title">Confirm Account Delete</div>
        <div className="confirm-delete-body">
          {err && <Message err={err} msgState={(data) => setErr(data)} />}
          Enter Password to proceed
          <input
            type="password"
            name=""
            id="pwd"
            value={pwd}
            onChange={(e) => setPwd(e.target.value)}
            autoFocus
          />
        </div>
        <div className="confirm-delete-btn">
          <div onClick={closeConfirm}>Cancel</div>
          <div
            className={stringLength(pwd, 5) && "delete_confirm"}
            onClick={() => {
              stringLength(pwd, 5) && deleteFunc();
            }}
          >
            Delete
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChangePwd = ({ closeConfirm }) => {
  const [data, setData] = useState({ pwd: "", newPwd: "", confirmPwd: "" });
  const [err, setErr] = useState({msg: false, type: false});
  const [loading, setLoading] = useState(false);

  const handleChange = (event, alt) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: alt || value,
    }));
  };

  const updatePwd = async () => {
    if (stringLength(data.pwd, 5)) {
      if (!stringLength(data.newPwd, 5)) {
        setErr({msg: "Password too short", type: false});
      } else if(!checkEquals(data.newPwd, data.confirmPwd)) {
        setErr({msg: "Password do not match", type: false});
      }
      else{
        setErr(false)
        setLoading(true)
        try {
          const resp = await chengePassword(data.pwd, data.newPwd)
          if (resp){
            setErr({ msg:"Password Changed!", type: true})
            setLoading(false)
            setTimeout(() => {
              closeConfirm();
            }, 1000);
          }
        } catch (error) {
         setLoading(false)
         setErr(error.response?.data?.message ? {msg: error.response.data.message, type: false} : {msg: "An error occured, Try again later", type: false})
        }
      }
    }
  };

  return (
    <div
      className="confirm-delete-container v2"
      onClick={(e) => {
        handleChange(e, "");
        closeConfirm();
      }}
    >
      <div className="confirm-delete" onClick={(e) => e.stopPropagation()}>
        <div className="confirm-delete-title">Update Password</div>
        {err.msg && (
          <Message err={err.msg} msgState={(data) => setErr(data)} color={err.type ? "#18B731" : "red"} />
        )}

        <div className="confirm-delete-body">
          Enter Your Password
          <input
            type="password"
            name="pwd"
            id="pwd"
            value={data.pwd}
            onChange={handleChange}
            autoFocus
          />
        </div>
        <div className="confirm-delete-body">
          Enter New Password
          <input
            type="password"
            name="newPwd"
            id="pwd"
            value={data.newPwd}
            onChange={handleChange}
          />
        </div>
        <div className="confirm-delete-body">
          Confirm New Password
          <input
            type="password"
            name="confirmPwd"
            id="pwd"
            value={data.confirmPwd}
            onChange={handleChange}
          />
        </div>
        <div className="confirm-delete-btn">
          <div onClick={closeConfirm}>Cancel</div>
          <div
            className={stringLength(data.pwd, 5) ? "update-confirm" : "update"}
            onClick={updatePwd}
          >
            Update
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};
