import Logo from "../logo/logo";
import "./error.scss";
import { Link, useRouteError } from "react-router-dom";
function Error({ msg }) {
  const error = useRouteError();
  return (
    <div className="error_page">
      <Logo />
      <div className="oops">Oops!</div>
      <div className="err_msg">
        {msg || "Sorry, an unexpected error has occured"}
      </div>
      <p>{error?.statusText || error?.message}</p>
      <Link style={{ fontSize: "18px" }} className="link" to="/login">
        Return to login page
      </Link>
    </div>
  );
}
export default Error;
