import { useEffect, useRef, useState } from "react";
import "./send.scss";
import {
  AutoHyperlink,
  ClusterId,
  convertTo12HourFormat,
  copyText,
  isEmojiOnly,
  isSingleEmoji,
  RepliedCard,
  replyMessage,
  truncateString,
} from "../../utils";
import ViewImage from "../viewImage";
import ProgressiveLazyImage from "../ProgressiveImage";

export default function Send({
  thumb,
  message,
  repliedMsg,
  time,
  setReply,
  cluster,
  startCluster,
  endCluster,
  singleCluster,
  copy,
  imageSrc,
  caption,
}) {
  const [msg, setMsg] = useState(message);
  const [pressTimer, setPressTimer] = useState(null);
  const [longText, setLongText] = useState(false);
  const [viewImg, setViewImg] = useState(false);
  var parentNode = document.querySelectorAll(".messageContainer")[0];
  const reply = () => {
    replyMessage(
      thumb,
      msg,
      caption,
      imageSrc ? imageSrc : null,
      parentNode,
      containerRef,
      setReply
    );
  };
  useEffect(() => {
    _message();
  }, [msg]);

  const _message = () => {
    message &&
      setMsg((prevText) => prevText.replace(/^(\n\s*)+|(\n\s*)+$/g, ""));
  };
  const replyBtnRef = useRef(null);
  const containerRef = useRef(null);
  const showReply = () => {
    replyBtnRef.current.parentElement.style.opacity = "1";
  };
  const hideReply = () => {
    replyBtnRef.current.parentElement.style.opacity = "0";
  };
  const swipe = () => {
    if (Math.ceil(containerRef.current.scrollLeft) === 100) {
      containerRef.current.lastChild.style.transform = "scale(1)";
      hideReply();
      replyBtnRef.current.click();
    } else {
      containerRef.current.lastChild.style.transform = "scale(0)";
      showReply();
    }
  };

  useEffect(() => {
    const clean = containerRef.current;
    const handleScroll = () => {
      swipe();
    };
    clean.addEventListener("scroll", handleScroll);
    return () => {
      clean.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const showReplied = () => {
    console.log(repliedMsg);
    try {
      parentNode.children[repliedMsg[2]].scrollIntoView({
        block: "center",
      });
      setTimeout(() => {
        if (parentNode.children[repliedMsg[2]] !== null) {
          parentNode.children[repliedMsg[2]].style.opacity = "0.5";
          setTimeout(() => {
            parentNode.children[repliedMsg[2]].style.opacity = "1";
          }, 400);
        }
      }, 200);
    } catch (error) {}
  };

  const checkLongText = () => {
    const clean = containerRef.current;
    try {
      if (clean) {
        let short = clean.firstChild.lastChild.children;
        const condition =
          short[short.length - 2].offsetHeight > 20 &&
          (short[short.length - 2].offsetWidth > 300 ||
            short[short.length - 2].offsetWidth > window.innerWidth * 0.6 - 20);
        return condition ? setLongText(true) : setLongText(false);
      }
    } catch (error) {}
  };
  useEffect(() => {
    const handleResize = () => {
      checkLongText();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    checkLongText();
  }, []);

  return (
    <div
      className="send-container"
      ref={containerRef}
      id={ClusterId(cluster, singleCluster, endCluster, startCluster)}
    >
      {viewImg && <ViewImage data={imageSrc} close={() => setViewImg(false)} />}
      <div className="send">
        <div className="reply">
          <span
            onClick={reply}
            ref={replyBtnRef}
            className="fas fa-reply"
          ></span>
          <span
            onClick={() => {
              copyText(msg ? msg : caption ? caption : "");
              (msg || caption) && copy(true);
            }}
            className="fa-regular fa-copy"
          ></span>
        </div>
        <div
          className={`sentMsg ${imageSrc ? "image-msg" : ""} ${
            !caption && imageSrc ? "no-caption" : ""
          }`}
          id={longText ? "longtext" : ""}
        >
          {repliedMsg ? (
            <RepliedCard repliedMsg={repliedMsg} showReplied={showReplied} />
          ) : (
            <></>
          )}
          {imageSrc && (
            <div className="message-img">
              <ProgressiveLazyImage
                alt="sent"
                data={imageSrc}
                onClick={() => setViewImg(true)}
              />
            </div>
          )}
          {msg && !imageSrc && (
            <div
              className={`message ${isEmojiOnly(message) ? "emoji" : ""} ${
                isSingleEmoji(message) ? "emoji-single" : ""
              }`}
            >
              {<AutoHyperlink text={msg} />}
            </div>
          )}
          {caption && imageSrc && (
            <div className="message">{<AutoHyperlink text={caption} />}</div>
          )}
          <div className="time">{convertTo12HourFormat(time)}</div>
        </div>
      </div>
      <div className="reply_alt" onClick={reply}>
        <span className="fas fa-reply"></span>
      </div>
    </div>
  );
}
