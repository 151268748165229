import Logo from "../../components/logo/logo";
import Nav from "./nav";
import './privacy.scss'


export default function Privacy(){
    return(
        <div className="privacy">
            <div className="policy_logo">
                <Nav />
                <Logo />
                <div className="privacy_title">AnonnChat Privacy Policy</div>
            </div>
            <div className="privacy_lists">
                <div className="top">
                    Last Updated: 2023
                </div>
                <div id="intro">                        
                    Welcome to <strong> AnonnChat </strong> the Anonymous Chat App. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information. By using the app, you consent to this policy.
                </div>
                <div className="info">
                    <div className="top">
                        Information We Collect
                    </div>
                    <div className="info_lists">
                        <ul className="dots">User-Generated Content: Messages, photos, and any other content you share within the app.</ul>
                        <ul className="dots">Device Information: Device type, operating system, and mobile network information.</ul>
                        <ul className="dots">Security: To protect against abuse, fraud, or any unauthorized access.</ul>
                        <ul className="dots">Legal Compliance: To comply with applicable laws and regulations.</ul>
                    </div>
                </div>
                <div className="sharing">
                    <div className="top">
                        Data Sharing
                    </div>
                    <div className="data">
                        <span>We do not share your personal information with third parties. However, please note that the nature of anonymous chat means that your messages and content may be visible to other users.</span>
                    </div>
                </div>
                <div className="sharing">
                    <div className="top">
                        Security
                    </div>
                    <div className="data">
                        <span> We take security seriously and employ measures to protect your data. However, please understand that the nature of anonymous chat involves certain risks. </span>
                    </div>
                </div>
                <div className="sharing">
                    <div className="top">
                        Changes to this Policy
                    </div>
                    <div className="data">
                        <span> We may update this policy to reflect changes in our data handling practices or legal requirements. You will be notified of any updates. </span>
                    </div>
                </div>
                <div class="top bottom">
                    By using <span className="name"> AnonnChat App </span>, you agree to this Privacy Policy.
                </div>
            </div>
        </div>
    )
}