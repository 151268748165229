import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "./groups.scss";
import Card from "../../components/homepage/cards/card";
import { convertToCustomFormatThumb, notAuthenticated } from "../../utils";
import { fetchUserRooms } from "../../services/api";
import Loader from "../../components/loader/loader";
import { openRoom, setCookie } from "../../utils";
import { GROUP_EMOJI_TIMEOUT } from "../../utils/constant";
import { Helmet } from "react-helmet";

export default function Group() {
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchRooms();
  }, []);

  useEffect(() => {
    rooms.forEach((room) => {
      setCookie(room.id, room.creator, GROUP_EMOJI_TIMEOUT);
    });
  }, [rooms]);

  const fetchRooms = async () => {
    try {
      let resp = await fetchUserRooms();
      if (resp.data) {
        setRooms(resp.data.data || []);
        console.log(resp.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      notAuthenticated();
    }
  };
  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content={"AnonnChat Group - Connecting Anonymously"}
        />
      </Helmet>
      <div className="groupPage">
        <Link to="/room/new">
          <div className="cards">
            <Card
              icon_name="fa-users"
              title="Create Group chat"
              desc="Share a link for your Anonymous Group chat"
            />
          </div>
        </Link>
        <div className="myGroups">
          <div className="myGroupsHead">My Groups</div>
          <div className="myGroupsContainer">
            {(rooms.length > 0 &&
              rooms?.map((room, _) => (
                <div
                  onClick={() => {
                    openRoom(room.id);
                  }}
                  key={room.id}
                  className="cards"
                >
                  <Card
                    icon_name="fa-user-friends"
                    title={room?.name}
                    desc={"Created : " + convertToCustomFormatThumb(room?.date)}
                    group={true}
                    id={room?.id}
                  />
                </div>
              ))) || (
              <div className="cards no-room">
                Sorry, no available rooms at the moment. Create one now
              </div>
            )}
          </div>
        </div>
        {loading && <Loader />}
      </div>
    </>
  );
}
