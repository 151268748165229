import { useEffect } from "react";
import { IndexHeader } from "../../components/IndexHeader";
import { LandingFooter } from "../landing";
import "./blog.scss";

export function Blog() {
  const blogData = [
    {
      date: "April 11, 2024",
      title: "Anonnchat Launched ",
      img: "blog-launched.webp",
      short_desc: "The wait is over Anonnchat is finally Launched",
      desc: "Exciting news! Anonnchat has officially launched! 🚀 Join us for anonymous and authentic conversations with like-minded individuals. No personal information required—just genuine connections. Create your unique username and start chatting today!",
    },
    {
      date: "April 09, 2024",
      title: "Coming Soon",
      img: "blog-prelaunch.webp",
      short_desc: "Anonnchat Announced to be launched soon...",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, fugiat nisi, odio ipsam voluptate optio dolore vero unde impedit aliquid esse laborum animi laboriosam neque quibusdam reprehenderit qui eaque ratione.",
    },
    // {
    //   date: "April 10, 2024",
    //   title: "Anonnchat Launched",
    //   img: "blog-prelaunch.webp",
    //   short_desc:
    //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, fugiat nisi odio ipsam voluptate optio dolore vero unde",
    //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, fugiat nisi, odio ipsam voluptate optio dolore vero unde impedit aliquid esse laborum animi laboriosam neque quibusdam reprehenderit qui eaque ratione.",
    // },
    // {
    //   date: "April 10, 2024",
    //   title: "Anonnchat Launched",
    //   img: "",
    //   short_desc:
    //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, fugiat nisi odio ipsam voluptate optio dolore vero unde",
    //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, fugiat nisi, odio ipsam voluptate optio dolore vero unde impedit aliquid esse laborum animi laboriosam neque quibusdam reprehenderit qui eaque ratione.",
    // },
    // {
    //   date: "April 10, 2024",
    //   title: "Anonnchat Launched",
    //   img: "",
    //   short_desc:
    //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, fugiat nisi odio ipsam voluptate optio dolore vero unde",
    //   desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, fugiat nisi, odio ipsam voluptate optio dolore vero unde impedit aliquid esse laborum animi laboriosam neque quibusdam reprehenderit qui eaque ratione.",
    // },
  ];
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div className="blog">
      <IndexHeader />
      <div className="blog-container">
        <div className="blog-title">Anonnchat Blog</div>
        {blogData.length < 1 ? (
          <BlogEmpty />
        ) : (
          blogData.map((data) => (
            <BlogCard
              date={data.date}
              title={data.title}
              img={data.img}
              short_desc={data.short_desc}
              desc={data.desc}
            />
          ))
        )}
      </div>
      <LandingFooter />
    </div>
  );
}

export const BlogCard = ({ date, title, img, short_desc, desc }) => {
  return (
    <div className="blog-card">
      <div className="blog-image">
        {img && (
          <img
            src={process.env.PUBLIC_URL + "/blog/" + img}
            alt="Anonnchat blog prelaunch"
          />
        )}
      </div>
      <div className="blog-content">
        <div className="blog-card-date">{date}</div>
        <div className="blog-card-title">{title}</div>
        <div className="blog-card-short_desc">{short_desc}</div>
        <div className="blog-content-action">See more </div>
      </div>
    </div>
  );
};

export const BlogEmpty = () => {
  return (
    <div className="blog-empty">
      Stay tuned for exciting updates! Keep an eye out for new features as we
      continue to enhance your experience. 😉
    </div>
  );
};
