import { useState, useEffect } from "react";
import "./newGroup.scss";
import Notify from "../../components/notification/notify";
import Nav from "../privacy/nav";
import { createRoom } from "../../services/api";
import { roomLink, copyText } from "../../utils";
import Loader from "../../components/loader/loader";
import Logo from "../../components/logo/logo";
import { setCookie, syncDashboard } from "../../utils";

export default function CreateGroup() {
  const [user, setUser] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [notify, setNotify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [_roomLink, setRoomLink] = useState(false);
  const groupNames = [
    "Confidential Collective",
    "Discreet Alliance",
    "Incognito Society",
    "Veiled Emissaries",
    "Silent Cipher Guild",
    "Phantom Assembly",
    "Masked Diplomats",
    "Obscure Union",
    "Ghostly Conclave",
    "Enigma Fraternal Order",
    "Covert Coalition",
    "Mysterious Fellowship",
    "Stealth Syndicate",
    "Cloaked Comrades",
    "Subtle Confederation",
    "Cryptic Consortium",
    "Anonymous Accord",
    "Ethereal Society",
    "Shadowed Guild",
    "Unseen Coalition",
  ];

  useEffect(() => {
    setLoading(true);
    syncDashboard(setUser, setLoading);
  }, []);

  const generate = async () => {
    setLoading(true);
    try {
      let resp = await createRoom(groupName);
      const room_id = resp.data.room_id;
      const creator = resp.data.creator;
      if (resp.status) {
        setCookie(room_id, creator, 2);
        setRoomLink(roomLink(room_id));
        setIsGenerated(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const userInput = (e) => {
    setGroupName(e.target.value);
  };

  const generateRnd = () => {
    const randomIndex = Math.floor(Math.random() * groupNames.length);
    setGroupName(groupNames[randomIndex]);
  };

  return (
    <div className="createGroup">
      <div className="createGroupContainer">
        <div className="createGroupHead">
          <Nav />
          <Logo />
          <div>Create Group</div>
        </div>

        <label className="groupName">
          <span>Enter Group name [At least 4 Characters]</span>
          <div className="group-inputarea">
            <input
              disabled={isGenerated}
              type="text"
              id="groupName"
              name="group_name"
              onChange={userInput}
              value={groupName}
              maxLength={30}
              autoFocus
            />
            {!isGenerated && (
              <i
                class="fa-solid fa-wand-magic-sparkles"
                onClick={() => generateRnd()}
              ></i>
            )}
          </div>
        </label>
        {isGenerated ? (
          <>
            <div className="results">
              <div className="groupLink">
                <div id="groupLink">{_roomLink}</div>
                <div className="border"></div>
                <span
                  onClick={() => {
                    copyText(_roomLink);
                  }}
                  className="fas fa-copy"
                ></span>
              </div>
              <div
                onClick={() => {
                  window.open(_roomLink, "_blank");
                }}
                className="openGroup"
              >
                Open Group
              </div>
            </div>
          </>
        ) : (
          groupName.length >= 4 && (
            <div onClick={generate} className="generate">
              Create Group
            </div>
          )
        )}
        <div className="group-notice">
          <Details content="The group is limited to a maximum of ten members." />
          <Details content="Each group member is assigned a random emoji for representation." />
          <Details content="The group will be automatically deactivated after 24 hours" />
        </div>
      </div>
      {loading && <Loader />}
      {notify && <Notify msg="Group link copied to clipboard" />}
    </div>
  );
}

export function Details({ content }) {
  return (
    <div className="group-details">
      <i class="fa-solid fa-circle-exclamation"></i>
      <span>{content}</span>
    </div>
  );
}
