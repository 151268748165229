import { useNavigate } from 'react-router-dom'
export default function Nav(){
    const style = {
        fontSize: '20px',
        cursor: 'pointer'
    }
    const history =  useNavigate();
    const navigate = () => {
        history(-1);
    }
    return(
        <div style={style} className="nav">
            <span onClick={navigate} className="fas fas fa-arrow-left"></span>
        </div>
    )
}