import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import "./capture.scss";
const Capture = ({ state, returnState, message }) => {
  const [saveState, setSaveState] = useState(
    "fa-solid fa-circle-notch fa-spin"
  );
  const captureRef = useRef(null);
  const [imgData, setImgData] = useState(false);

  useEffect(() => {
    if (captureRef.current) {
      convertToImage();
    }
  }, [captureRef]);

  useEffect(() => {
    if (imgData) {
      setSaveState("fa-regular fa-floppy-disk");
    }
  }, [imgData]);

  const convertToImage = () => {
    const divElement = captureRef.current;

    html2canvas(divElement).then((canvas) => {
      const dataUrl = canvas.toDataURL("image/png") || false;
      console.log("Image URL:", dataUrl);
      setImgData(dataUrl);
    });
  };

  const closeCapture = () => {
    returnState(false);
  };

  const saveImage = () => {
    if (imgData) {
      setSaveState("fa-solid fa-circle-notch fa-spin");
      setTimeout(() => {
        setSaveState("fa-regular fa-circle-check");
        setTimeout(() => {
          setSaveState("fa-regular fa-floppy-disk");
        }, 1500);
      }, 1000);
      const downloadLink = document.createElement("a");
      downloadLink.href = imgData;
      downloadLink.download = "AnonInbox.png";

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      closeCapture();
    }
  };

  const handlePopState = (e) => {
    e.preventDefault();
    closeCapture();
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("keydown", handleKeyPress);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div
      onClick={closeCapture}
      className="capture-container"
      id={state && "capture-show"}
    >
      <div className="capture-options" onClick={(e) => e.stopPropagation()}>
        <i class={saveState} onClick={saveImage}></i>
        <i class="fa-solid fa-xmark" onClick={() => closeCapture()}></i>
      </div>
      <div
        className="capture-image-container"
        ref={captureRef}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="capture-image">
          <div className="capture-head">
            <img
              width={"70px"}
              src={process.env.PUBLIC_URL + "/logo_light.png"}
              alt=""
            />
          </div>
          <div className="capture-body">{message}</div>
        </div>
        <div className="appName">AnonnChat</div>
      </div>
    </div>
  );
};

export default Capture;
