import "./auth.scss";
import Logo from "../logo/logo";
import Message from "../message/Message";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../loader/loader";
import Footer from "../footer/footer";
import {validateEmail} from "../../utils"
import {resetPassword} from '../../services/api'
function Reset() {
  const [hasMsg, setHasMsg] = useState({
    visibility: false,
    message: null,
    error: null,
  });
  const [loading, setLoading] = useState(false);
  const [passwordResetForm, setPasswordResetForm] = useState({email: "" });
  const msgState = () => {
    setHasMsg(!hasMsg);
  };

  const emailKeyUp = (value) => {
    setPasswordResetForm({ ...passwordResetForm, email: value });
  };

  const submit = async() => {
    if(!validateEmail(passwordResetForm.email)){
      setHasMsg({
        visibility: true,
        message: "Invalid Email",
        error: true,
      });
    }
    else{
      try {
        setLoading(true);
        let resp = await resetPassword(passwordResetForm);
        if (resp.status) {
          setHasMsg({
            visibility: true,
            message: resp.data.message,
            error: false,
          });
          setLoading(false);
        }
      } catch (error) {
        setHasMsg({
          visibility: true,
          message: error.response.data.message,
          error: true,
        });
        setLoading(false);
      }
    }
  };

  return (
    <div className="box_container">
      <div className="logo">
        <Logo />
      </div>
      <div className="title">Reset Password</div>
      {hasMsg.visibility && (
        <Message
          err={hasMsg.message}
          color={hasMsg.error ? "#F02A50" : "#49D87E"}
          msgState={msgState}
        />
      )}

      <div className="form" action="">
        <div className="username">
          <label htmlFor="username">
            Enter your email address to receive a new password.
          </label>
          <input
            onKeyUp={(e) => {
              emailKeyUp(e.target.value);
            }}
            type="email"
            name="email"
            id="username"
          />
        </div>
        <div className="button" onClick={submit}>Send Reset Link</div>
      </div>
      <div className="options">
        New to AnonnChat?{" "}
        <Link to="/signup">
          {" "}
          <span className="link">Create Account</span>{" "}
        </Link>
      </div>
      {loading && <Loader />}
    </div>
  );
}

export default Reset;
