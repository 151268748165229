import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import "./chat.scss";
import Send from "../../components/send/send";
import Receive from "../../components/receive/receive";
import { getRoom } from "../../services/api";
import {
  WS_URL,
  NOTIFICATION,
  CHAT,
  WS_SEND_ACTION,
  ADD_CONNECTION,
  ACTIVE_USERS,
  IMAGE,
} from "../../utils/constant";
import {
  areImagesLoaded,
  clusterCheck,
  convertToCustomFormat,
  endClusterCheck,
  getCurrentUTCTime,
  getEmoji,
  getTimeDifference,
  isMobileBrowser,
  PlaySound,
  randomString,
  ResetFields,
  singleClusterCheck,
  startClusterCheck,
} from "../../utils";
import { useMessage } from "../../context/MessageContext";
import { Helmet } from "react-helmet";
import MessageOption from "../../components/options";
import Loader from "../../components/loader/loader";
import MessageBox from "../../components/messageBox";
import ChatHead from "../../components/chatHead";
import ProcessImage from "../../components/processing";

export default function Chat() {
  const { roomId } = useParams();
  const { state, dispatch } = useMessage(roomId);
  const [input, setInput] = useState("");
  const [caption, setCaption] = useState("");
  const [replyMsg, setReplyMsg] = useState();
  const [repliedMsg, setRepliedMsg] = useState("");
  const [groupName, setGroupName] = useState(false);
  const [socket, setSocket] = useState(null);
  const [reRenderPatch, setReRenderPatch] = useState(0);
  const sendMessage = useRef(null);
  const messageContainer = useRef(null);
  const chatBox = useRef(null);
  const msgTextArea = useRef(null);
  const [groupDate, setGroupDate] = useState();
  const [creator, setCreator] = useState();
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [options, setOptions] = useState();
  const [shift, setShift] = useState(0);
  const [copyNotify, setCopyNotify] = useState(false);
  const [notifyTimeout, setNotifyTimeout] = useState(null);
  const [expireTimeout, setExpireTimeout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState({ all: [], active: [] });
  const [imgSrc, setImgSrc] = useState(false);
  const [process, setProcess] = useState(false);

  const [isWindowLoaded, setIsWindowLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setReRenderPatch(reRenderPatch + 1);
    const clean = chatBox.current.parentElement;
    if (clean && isMobileBrowser()) {
      clean.classList.add("view-mobile");
    } else {
      clean.classList.remove("view-desktop");
    }
  }, []);

  useEffect(() => {
    expireTimeout &&
      setTimeout(() => {
        localStorage.removeItem(roomId);
        navigate("/expired");
      }, expireTimeout);
  }, [expireTimeout, navigate, roomId]);

  try {
    const y = getTimeDifference(
      state?.messages[7].time,
      state?.messages[6].time
    );
  } catch (error) {}

  useEffect(() => {
    // console.log(reRenderPatch);
    if (reRenderPatch == 1) {
      // load room in
      initialDataLoad();
    }
  }, [reRenderPatch]);

  useEffect(() => {
    if (reRenderPatch == 1) {
      // socket connection
      socketConn();
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [state.sender]);

  const initialDataLoad = async () => {
    const data = await getRoom(roomId);
    const emoji = await getEmoji(roomId);
    dispatch({ type: "SET_SENDER", payload: emoji });
    dispatch({ type: "INIT_MSG", payload: data?.messages });
    setGroupName(data?.name);
    setGroupDate(data.date);
    setCreator(data.creator);
    setMember((prevData) => ({ ...prevData, all: data.members }));
    // console.log(data);

    const currentTimestamp = Date.now();
    const targetTimestamp = parseInt(data.expire_at) * 1000;
    const difference = targetTimestamp - currentTimestamp;
    setExpireTimeout(difference);
  };

  const reconnectSocket = async () => {
    const data = await getRoom(roomId);
    dispatch({ type: "INIT_MSG", payload: data?.messages });
    setMember((prevData) => ({ ...prevData, all: data.members }));
    socketConn();
  };

  const socketConn = async () => {
    // Create a new WebSocket connection
    const newSocket = new WebSocket(WS_URL);

    // Set up event listeners for the WebSocket
    newSocket.onopen = () => {
      // console.log("WebSocket connection opened");
      const addConnection = {
        action: WS_SEND_ACTION,
        room_id: roomId,
        client: state.sender,
        message: { event: ADD_CONNECTION },
      };
      // console.log(state.sender);
      newSocket.send(JSON.stringify(addConnection));
    };

    newSocket.onmessage = (event) => {
      // console.log("WebSocket message received:", event.data);
      let resp = JSON.parse(event.data);

      if (resp?.event === CHAT) {
        // Handle incoming messages from the WebSocket
        const newMsg = {
          id: resp.id,
          sender: resp.sender,
          message: resp.message,
          reply: resp.reply === "" ? null : resp.reply,
          time: resp.time,
        };
        dispatch({ type: "ADD_MESSAGE", payload: newMsg });
      } else if (resp?.event === IMAGE) {
        // Handle incoming messages from the WebSocket
        const newMsg = {
          id: resp.id,
          sender: resp.sender,
          image_src: resp.image_src,
          caption: resp.caption,
          reply: resp.reply === "" ? null : resp.reply,
          time: resp.time,
        };
        dispatch({ type: "ADD_MESSAGE", payload: newMsg });
      } else if (resp?.event === NOTIFICATION) {
        dispatch({ type: "ADD_MESSAGE", payload: resp });
      } else if (resp?.event === ACTIVE_USERS) {
        setMember((prevData) => ({ ...prevData, active: resp.active_users }));
        // console.log("active", resp.active_users);
      } else {
        console.log("unknown message");
      }
    };

    newSocket.onclose = (event) => {
      // console.log("WebSocket connection closed:", event.code, event.reason);

      console.log("Reconnecting...");
      reconnectSocket();
    };

    setSocket(newSocket);
  };

  const getActiveUsers = (e) => {
    const activeUser = {
      action: WS_SEND_ACTION,
      room_id: roomId,
      message: { event: ACTIVE_USERS },
    };
    socket.send(JSON.stringify(activeUser));
  };

  const sendMsg = ({ image }) => {
    scrollToBottom();
    if (!whiteSpace(input) || image) {
      PlaySound();
      const newMsg = {
        action: WS_SEND_ACTION,
        room_id: roomId,
        id: randomString(15),
        message: {
          event: image ? IMAGE : CHAT,
          sender: state.sender,
          message: image ? null : input,
          caption: image ? caption : null,
          image_src: image ? image : null,
          reply: repliedMsg === "" ? null : repliedMsg,
          time: getCurrentUTCTime(),
        },
      };
      // console.log(newMsg);
      socket.send(JSON.stringify(newMsg));
      // getActiveUsers
      getActiveUsers();
      dispatch({
        type: "ADD_MESSAGE",
        payload: { ...newMsg.message, id: newMsg.id },
      });
      ResetFields(setInput, setRepliedMsg, setReplyMsg, setCaption);
    }
  };

  const userInput = (e) => {
    if (e.target.scrollHeight < 500) {
      setInput(e.target.value);
    }
  };
  const userCaption = (e) => {
    if (e.target.scrollHeight < 500) {
      setCaption(e.target.value);
    }
  };
  const focusMsg = (e) => {
    if (msgTextArea.current) {
      msgTextArea.current.focus();
      msgTextArea.current.parentElement.parentElement.style.borderBottomColor =
        "#0291E3";
    }
  };

  function whiteSpace(text) {
    const regex = /^\s*$/;
    return regex.test(text);
  }
  const reply = ([thumb, msg, index, imgSrc]) => {
    setRepliedMsg([thumb, msg, `${index}`, imgSrc]);
    setReplyMsg([thumb, msg, index, imgSrc]);
    // console.log(index);
    focusMsg();
  };

  const closeReply = () => {
    setRepliedMsg("");
    setReplyMsg(false);
    focusMsg();
  };

  useEffect(() => {
    if (
      scrollHeight -
        (messageContainer.current &&
          messageContainer.current.offsetHeight + scrollTop) <
      400
    ) {
      scrollToBottom(true);
    }
  }, [state.messages]);

  useEffect(() => {
    setShift(`${sendMessage.current.offsetHeight - 50}`);
  }, [repliedMsg, input]);

  useEffect(() => {
    const clean = messageContainer.current;
    const handleScroll = () => {
      if (messageContainer.current) {
        const newScrollTop = messageContainer.current.scrollTop;
        const newScrollHeight = messageContainer.current.scrollHeight;
        setScrollTop(newScrollTop);
        setScrollHeight(newScrollHeight);
      }
    };

    if (messageContainer.current) {
      messageContainer.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (clean) {
        clean.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const scrollToBottom = (auto) => {
    messageContainer.current.scrollTo({
      top: messageContainer.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const copyMsg = () => {
    setCopyNotify(true);
    clearTimeout(notifyTimeout);
    const timeout = setTimeout(() => {
      setCopyNotify(false);
    }, 2000);
    setNotifyTimeout(timeout);
  };

  const LoadData = async () => {
    const data = await getRoom(roomId);
    setMember((prevData) => ({ ...prevData, all: data.members }));
    getActiveUsers();
  };

  const GroupNotif = (user) => {
    LoadData();
    return <GroupInfo data={user?.message} />;
  };

  return (
    <div className="chatBox">
      <Helmet>
        {groupName ? (
          <title>{`${groupName} || AnonnChat`}</title>
        ) : (
          <title>Room || AnonnChat</title>
        )}
      </Helmet>
      <div className="chatContainer" ref={chatBox}>
        <ChatHead
          groupName={groupName}
          members={member}
          creator={creator}
          getActiveUsers={getActiveUsers}
        />
        <div
          className="messageContainer"
          ref={messageContainer}
          style={{
            marginBottom: `${shift}px`,
          }}
        >
          <div className="empty" style={{ height: `${shift * 1.5}px` }}></div>
          {groupDate && <GroupInfo data={convertToCustomFormat(groupDate)} />}
          {creator && (
            <GroupInfo data={`${creator ? creator : ""} Created this group`} />
          )}
          {/* <Receive
            thumb={"😶"}
            key={"user?."}
            message={"Waxxup"}
            repliedMsg={["🥲", "#2B3543#2B3543#2B3543", 1]}
            time={"12:45 AM"}
            setReply={reply}
          /> */}
          {/* <Receive
            thumb={"🙂‍↔"}
            key={1000}
            repliedMsg={["🙂‍↔️", "Pussy"]}
            time={"12:30 PM"}
            caption={"Gray man"}
            setReply={reply}
            onLongPress={(msgData) => setOptions(msgData)}
            copy={copyMsg}
            imageSrc={"https://picsum.photos/1/1"}
          />
          <Send
            thumb={"🙂‍↔"}
            key={1000}
            repliedMsg={["🙂‍↔️", "Pussy", 1, "https://picsum.photos/2000/200"]}
            time={"12:30 PM"}
            caption={"Pussy Pussy Pussy Pussy Pussy Pussy Pussy "}
            setReply={reply}
            onLongPress={(msgData) => setOptions(msgData)}
            copy={copyMsg}
            imageSrc={"https://picsum.photos/200/100"}
          /> */}
          {state?.messages?.map((user, i) =>
            user?.event === NOTIFICATION ? (
              GroupNotif(user)
            ) : user?.sender === state.sender ? (
              <Send
                thumb={user?.sender}
                key={user?.id}
                message={user.message}
                repliedMsg={user.reply}
                caption={user?.caption}
                imageSrc={user?.image_src}
                time={user.time}
                setReply={reply}
                onLongPress={(msgData) => setOptions(msgData)}
                cluster={clusterCheck(state, i)}
                endCluster={endClusterCheck(state, i)}
                startCluster={startClusterCheck(state, i)}
                singleCluster={singleClusterCheck(state, i)}
                copy={copyMsg}
              />
            ) : (
              <Receive
                thumb={user?.sender}
                key={user?.id}
                message={user.message}
                repliedMsg={user.reply}
                caption={user?.caption}
                imageSrc={user?.image_src}
                time={user.time}
                setReply={reply}
                onLongPress={(msgData) => setOptions(msgData)}
                cluster={clusterCheck(state, i)}
                endCluster={endClusterCheck(state, i)}
                startCluster={startClusterCheck(state, i)}
                singleCluster={singleClusterCheck(state, i)}
                copy={copyMsg}
              />
            )
          )}
          {/* Spare Me */}
          {/* {options && (
            <MessageOption
              data={options}
              setRepliedMsg={(data) => {
                setRepliedMsg(data.reply);
                setReplyMsg(data.reply);
              }}
            />
          )} */}
        </div>
        <div
          onClick={scrollToBottom}
          className={`scroll-to-bottom ${
            scrollHeight - scrollTop - window.innerHeight > 500 && "show"
          }`}
          style={{ bottom: `${shift}px` }}
        >
          <span className="material-symbols-rounded">arrow_downward</span>
        </div>
        <div className="sendMessage" ref={sendMessage}>
          <MessageBox
            id={roomId}
            closeReply={closeReply}
            copyNotify={copyNotify}
            focusMsg={focusMsg}
            input={input}
            caption={caption}
            msgTextArea={msgTextArea}
            replyMsg={replyMsg}
            sendMsg={sendMsg}
            state={state}
            userInput={userInput}
            userCaption={userCaption}
            whiteSpace={whiteSpace}
            imgSrc={(data) => setImgSrc(data)}
            setProcess={(e) => setProcess(e)}
            process={process}
          />
        </div>
        {process && (
          <ProcessImage data={process} retry={(e) => setProcess(e)} />
        )}
      </div>
      {loading && <Loader />}
    </div>
  );
}

export const GroupInfo = ({ optional, data }) => {
  return (
    <div className="group-info">
      {optional && <span>{optional}</span>}
      <span>{data}</span>
    </div>
  );
};
