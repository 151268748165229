import { Button, IndexHeader } from "../../components/IndexHeader";
import { useInView } from "react-intersection-observer";
import "./landing.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { clearAllInvalidGroups } from "../../utils";

export function Landing() {
  const cardData = [
    {
      title: "Receive Anonn Messages",
      desc: "Share your unique Anonn link with friends and family to allow them to send you anonymous messages without revealing their identity",
      img: "Google-Pixel-6-PRO-localhost-8.png",
      special: "Anonn",
    },
    {
      title: "Capture Inbox as Image",
      desc: "Save your anonymous inbox as an image and share it on social media or with others to showcase your conversations.",
      img: "Google-Pixel-6-PRO-localhost-11.png",
      special: "Capture",
    },
    {
      title: "Anonn Group Chats",
      desc: "Create anonymous group chats where all members are identified by a random emoji. These groups last for only 2 hours, ensuring complete anonymity.",
      img: "Google-Pixel-6-PRO-localhost-14.png",
      special: "Group",
    },
    {
      title: "Quick Chat Tools",
      desc: "Swipe to reply and tap to copy messages, ensuring smooth conversations and easy sharing.",
      img: "Google-Pixel-6-PRO-localhost-19.png",
      special: "Tools",
    },
    {
      title: "Your Personal Anonn Link",
      desc: "Create an Anonn account to generate your own unique link, allowing you to share it and receive anonymous messages.",
      img: "Google-Pixel-6-PRO-localhost.png",
      special: "Anonn",
    },
  ];

  useEffect(() => {
    clearAllInvalidGroups();
  }, []);

  return (
    <div className="landing">
      <IndexHeader />
      <LandingDisplay />
      <LandingRibbon />
      {cardData.map((data, i) => (
        <LandingCard
          title={data.title}
          desc={data.desc}
          img={data.img}
          i={i}
          special={data.special}
        />
      ))}
      <LandingFooter />
    </div>
  );
}

export const LandingDisplay = () => {
  const [landingRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <div className="landing-container">
      <div
        className={`landing-display banner ${inView ? "land-inview" : ""}`}
        ref={landingRef}
      >
        <div className="landing-display-text display-text">
          <h1>Chat privately</h1>
          <span>
            Express freely. Your anonymous space for candid conversations, free
            for everyone
          </span>
          <Button text={"Get Started"} link={"/getstarted"} />
        </div>
        <div className="landing-display-image">
          <img
            src={
              process.env.PUBLIC_URL +
              "/screenshots/Google-Pixel-6-PRO-localhost-11.png"
            }
            alt="AnonnChat"
            id="logo_label"
          />
        </div>
      </div>
    </div>
  );
};

export const LandingRibbon = () => {
  const [landingRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.7,
  });
  return (
    <div
      ref={landingRef}
      className={`landing-ribbon ${inView ? "land-inview" : ""}`}
    >
      <h1>
        From heartfelt confessions to lively discussions and playful banter,
        ensure your privacy with AnonnChat robust privacy features.
      </h1>
    </div>
  );
};

export const LandingCard = (props) => {
  const [landingRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [text, setText] = useState(props.title);

  useEffect(() => {
    const regex = new RegExp(props.special, "g");
    const updatedText = props.title.replace(
      regex,
      `<span class='special'>${props.special}</span>`
    );
    setText(updatedText);
  }, [props.special, props.title]);

  return (
    <div className="landing-container">
      <div
        className={`landing-display landing-card ${
          inView ? "land-inview" : ""
        }`}
        ref={landingRef}
      >
        <div className="landing-display-text card-text">
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <span>{props.desc}</span>
        </div>
        <div className="landing-display-image">
          <img
            src={process.env.PUBLIC_URL + "/screenshots/" + props.img}
            alt="AnonnChat"
          />
        </div>
      </div>
    </div>
  );
};

export const LandingFooter = () => {
  return (
    <div className="landing-footer">
      <div className="landing-footer-copy">2024 © Anonnchat</div>
      <div className="landing-footer-links">
        <Link to={"/terms"}>Terms</Link>
        <i>&bull;</i>
        <Link to={"/privacy"}>Privacy</Link>
      </div>
      <div
        className="landing-footer-btt"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <span class="material-symbols-rounded">upgrade</span>
      </div>
    </div>
  );
};
