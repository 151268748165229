import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./header.scss";

export function IndexHeader() {
  const menuItems = ["blog", "about us", "feedback", "sign in"];
  const navigate = useNavigate();
  const menuLink = ["/blog", "/about", "/feedback", "/login"];
  const [windowScrollY, setWindowScrollY] = useState(0);
  const [menuState, setMenuState] = useState(false);

  useEffect(() => {
    function handleScroll() {
      setWindowScrollY(window.scrollY);
      console.log(windowScrollY);
      setMenuState(false);
    }
    window.addEventListener("wheel", handleScroll);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleScroll);
    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleScroll);
    };
  }, []);
  return (
    <div
      className={`landing-header ${
        windowScrollY > 25 ? "land-head-border" : ""
      }`}
    >
      <div className="landing-menu-icon">
        <span
          class="material-symbols-rounded"
          onClick={(e) => {
            e.stopPropagation();
            setMenuState(!menuState);
          }}
        >
          menu
        </span>
      </div>
      <div className="landing-logo" onClick={() => navigate("/")}>
        <img
          src={process.env.PUBLIC_URL + "/logo_text_dark.png"}
          alt="AnonnChat"
          id="logo_label"
        />
      </div>
      <div className="landing-menu" id={menuState ? "show" : "hide"}>
        {menuItems.map((item, i) => (
          <div
            className="landing-menu-items"
            onClick={() => navigate(menuLink[i])}
          >
            {item}
          </div>
        ))}
        <span className="land-magnifier"></span>
      </div>
      <div className="landing-action">
        <Button text={"Sign Up"} link={"/signup"} />
      </div>
    </div>
  );
}

export const Button = (props) => {
  const navigate = useNavigate();

  return (
    <div
      className={`landing-button ${props.border ? "land-border" : ""}`}
      onClick={() => props.link && navigate(props.link)}
    >
      <span>{props.text} </span>
      <span class="material-symbols-rounded">double_arrow</span>
    </div>
  );
};
