import { Link } from "react-router-dom";
import Logo from "../../components/logo/logo";
import Nav from "./nav";
import "./privacy.scss";
export default function Terms() {
  return (
    <div id="terms_container">
      <Nav />
      <div className="terms_logo">
        <Logo />
      </div>
      <header>
        <h1>Terms and Conditions</h1>
      </header>
      <main>
        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing and using the App, you acknowledge that you have read,
            understood, and agree to be bound by these Terms.
          </p>
        </section>
        <section>
          <h2>2. Age Restrictions</h2>
          <p>
            The App is intended for individuals who are at least 18 years old.
            If you are under 18 years of age, you are not permitted to use the
            App.
          </p>
        </section>
        <section>
          <h2>3. Privacy</h2>
          <p>
            Your use of the App is also governed by our{" "}
            <Link className="special" to={"/privacy"}> Privacy Policy</Link>. By using the App, you
            consent to the collection and use of your information as described
            in the Privacy Policy.
          </p>
        </section>
        <section>
          <h2>4. User Conduct</h2>
          <p>
            You agree that you will not use the App to engage in any unlawful,
            harmful, or inappropriate activities, including but not limited to:
          </p>
          <ul>
            <li>Harassment or threats towards other users.</li>
            <li>Posting offensive or illegal content.</li>
            <li>Impersonating others or misrepresenting your identity.</li>
            <li>Violating the privacy of other users.</li>
          </ul>
          <p>
            We reserve the right to terminate or suspend your access to the App
            if you engage in any conduct that violates these Terms or is harmful
            to other users.
          </p>
        </section>
      </main>
    </div>
  );
}
