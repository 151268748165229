import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { userMessages } from "../../../services/api";

export default function HomeHeader({ setThisPage, containerRef, updateSeen }) {
  const [currentPage, setCurrentPage] = useState("home");
  const [unread, setUnread] = useState("0");

  const fetchMessages = async () => {
    try {
      let resp = await userMessages();
      if (resp.data.message) {
        setUnread(resp.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const memoizedSetThisPage = useCallback((page) => {
    setThisPage(page);
  }, []);

  useEffect(() => {
    unread > 0 && setUnread(unread - 1);
  }, [updateSeen]);

  console.log(updateSeen);

  useEffect(() => {
    fetchMessages();
    memoizedSetThisPage(currentPage);
  }, [currentPage, memoizedSetThisPage]);
  console.log(currentPage);

  const nav = (e) => {
    const siblings = Array.from(e.parentElement.children).filter(
      (child) => child !== e
    );

    siblings.forEach((sibling) => {
      sibling.classList = "menuItems";
    });
    e.classList = "menuItems current_page";
  };
  const swipe = (e, x) => {
    const swipe =
      e.parentElement.parentElement.parentElement.lastChild.children[x];
    swipe.scrollIntoView({
      block: "nearest",
    });
  };

  const bodyRef = containerRef.current;
  const homeRef = useRef(null);
  const inboxRef = useRef(null);
  const groupsRef = useRef(null);
  const conditions = () => {
    if (bodyRef.scrollLeft < bodyRef.offsetWidth / 2) {
      setCurrentPage("home");
      nav(homeRef.current);
    } else if (
      bodyRef.scrollLeft >= bodyRef.offsetWidth / 2 &&
      bodyRef.scrollLeft < 1.5 * bodyRef.offsetWidth
    ) {
      setCurrentPage("inbox");
      nav(inboxRef.current);
    } else if (bodyRef.scrollLeft > 1.5 * bodyRef.offsetWidth) {
      setCurrentPage("groups");
      nav(groupsRef.current);
    }
  };

  if (bodyRef && inboxRef && homeRef && groupsRef) {
    bodyRef.addEventListener("scroll", conditions);
  }

  const home = (e) => {
    setCurrentPage("home");
    swipe(e.target, 0);
  };
  const inbox = (e) => {
    setCurrentPage("inbox");
    swipe(inboxRef.current, 1);
  };
  const groups = (e) => {
    setCurrentPage("groups");
    swipe(e.target, 2);
  };

  return (
    <div className="homeHeader">
      <div className="homeTitle">
        <div className="appName">AnonnChat</div>
        <Link to={"/settings"} tabIndex={1}>
          <span className="fas fa-cog"></span>
        </Link>
      </div>
      <div className="menu">
        {
          //currentPage === 'home' ?
          <>
            <div
              onClick={home}
              ref={homeRef}
              className="current_page menuItems"
              tabIndex={1}
            >
              Home
            </div>
            <div
              onClick={inbox}
              ref={inboxRef}
              className="menuItems"
              tabIndex={1}
            >
              Inbox
              {unread > 0 && <span>{unread}</span>}
            </div>
            <div
              onClick={groups}
              ref={groupsRef}
              className="menuItems"
              tabIndex={1}
            >
              Groups
            </div>
          </>
          // : currentPage === 'inbox' ?
          //     <>
          //         <div onClick={inbox} className="current_page menuItems">Inbox</div>
          //         <div onClick={home} className="menuItems">Home</div>
          //         <div onClick={groups} className="menuItems">Groups</div>
          //     </>
          // : currentPage === 'groups' ?
          //     <>
          //         <div onClick={inbox} className="menuItems">Inbox</div>
          //         <div onClick={home} className="menuItems">Home</div>
          //         <div onClick={groups} className="current_page menuItems">Groups</div>
          //     </>
          // : <></>
        }
      </div>
    </div>
  );
}
