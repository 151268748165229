import logo from "./logo.svg";
import "./App.scss";
import Login from "./components/auth/login";
import Signup from "./components/auth/signup";
import Reset from "./components/auth/reset";
import { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Error from "./components/error/Error";
import Feedback from "./components/auth/feedback";
import Footer from "./components/footer/footer";
import Homepage from "./components/homepage/homepage";
import Terms from "./pages/privacy/terms";
import Privacy from "./pages/privacy/privacy";
import CreateGroup from "./pages/newGroup/newGroup";
import Chat from "./pages/chat/chat";
import Compose from "./pages/compose/Compose";
import Settings from "./pages/settings/settings";
import { MessageProvider } from "./context/MessageContext";
import Index from "./pages/index";
import { Landing } from "./pages/landing";
import { AboutUs } from "./pages/about";
import { Blog } from "./pages/blog";
function App() {
  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      document.body.classList.add("mobile");
      // const addTextCursorClass = (elements) => {
      //   elements.forEach((element) => {
      //     element.classList.add("text-cursor");
      //   });
      // };

      // const divElements = document.querySelectorAll("div");
      // const spanElements = document.querySelectorAll("span");
      // const iElements = document.querySelectorAll("i");

      // addTextCursorClass(divElements);
      // addTextCursorClass(spanElements);
      // addTextCursorClass(iElements);
    } else {
      document.body.classList.add("pc");
    }
  }, []);

  const links = createBrowserRouter([
    {
      path: "login",
      element: (
        <>
          <Login /> <Footer />
        </>
      ),
    },
    {
      path: "signup",
      element: (
        <>
          <Signup /> <Footer />
        </>
      ),
    },
    {
      path: "reset",
      element: (
        <>
          <Reset /> <Footer />
        </>
      ),
    },
    {
      path: "feedback",
      element: <Feedback />,
    },
    {
      path: "privacy",
      element: <Privacy />,
      errorElement: <Error />,
    },
    {
      path: "terms",
      element: <Terms />,
    },
    {
      path: "homepage",
      element: (
        <>
          <Homepage /> <Footer />
        </>
      ),
    },
    {
      path: "room/new",
      element: (
        <>
          <CreateGroup />
        </>
      ),
    },
    {
      path: "settings",
      element: <Settings />,
    },
    {
      path: "error",
      element: <Error />,
    },
    {
      path: "/",
      element: <Landing />,
      errorElement: <Error />,
    },
    {
      path: "getstarted",
      element: <Index />,
    },
    {
      path: "index",
      element: <Landing />,
    },
    {
      path: "about",
      element: <AboutUs />,
    },
    {
      path: "blog",
      element: <Blog />,
    },
    {
      path: ":username",
      element: (
        <>
          <Compose /> <Footer />
        </>
      ),
    },
    {
      path: "room/:roomId",
      element: (
        <MessageProvider>
          <Chat />
        </MessageProvider>
      ),
      errorElement: <Error />,
    },
    {
      path: "expired",
      element: <Error msg={"Sorry, It Looks Like This Group Has Expired"} />,
    },
    {
      path: "roomfull",
      element: (
        <Error msg={"Sorry, It Looks Like This Group Is Full Already 💔"} />
      ),
    },
  ]);

  return (
    <div className="container">
      <RouterProvider router={links} />
    </div>
  );
}
export default App;
