import { useState } from "react";
import Logo from "../logo/logo";
import Message from "../message/Message";
import { Link } from "react-router-dom";
import { sendFeedback } from "../../services/api";
import { stringLength, truncateString } from "../../utils";
import Loader from "../loader/loader";

export default function Feedback() {
  const [fileName, setFileName] = useState("Upload Screenshot (Optional)");
  const [hasMsg, setHasMsg] = useState({
    visibility: false,
    message: null,
    error: null,
  });
  const [loading, setLoading] = useState(false);
  const [feedbackForm, setFeedbackForm] = useState({
    feedback: "",
    feedback_image: null,
  });

  const msgState = () => {
    setHasMsg(!hasMsg);
  };

  const submitFeedback = async () => {
    if (!stringLength(feedbackForm.feedback, 10)) {
      setHasMsg({
        visibility: true,
        message: "Feedback must contain over 10 characters",
        error: true,
      });
    } else {
      try {
        setLoading(true);
        let resp = await sendFeedback(feedbackForm);
        if (resp.status) {
          setHasMsg({
            visibility: true,
            message: "Feedback sent",
            error: false,
          });
          setLoading(false);
          setFeedbackForm({ feedback: "", feedback_image: null });
        }
      } catch (error) {
        console.log(error);
        setHasMsg({
          visibility: true,
          message: error.response.data.message,
          error: true,
        });
        setLoading(false);
      }
    }
  };

  const feedbackChange = (value) => {
    setFeedbackForm({ ...feedbackForm, feedback: value });
  };

  const checkScreenshot = (e) => {
    if (e.target.files.length > 0) {
      setFeedbackForm({ ...feedbackForm, feedback_image: e.target.files[0] });
      setFileName(truncateString(e.target.files[0].name, 20));
    }
  };

  return (
    <div className="box_container">
      <div className="logo">
        <Logo />
      </div>
      <div className="title">Feedback</div>
      {hasMsg.visibility && (
        <Message
          err={hasMsg.message}
          color={hasMsg.error ? "#F02A50" : "#49D87E"}
          msgState={msgState}
        />
      )}

      <div className="form">
        <textarea
          placeholder="Enter your Feedback"
          style={{ resize: "none" }}
          name="feedback"
          id="feedback"
          rows="5"
          onChange={(e) => {
            feedbackChange(e.target.value);
          }}
        ></textarea>
        <label htmlFor="file" className="file">
          <input
            onChange={checkScreenshot}
            type="file"
            name="feedback_screenshot"
            id="file"
          />
          <span>{fileName}</span>
        </label>
        <div className="button" onClick={submitFeedback}>
          Send Feedback
        </div>
      </div>
      <div className="options">
        New to AnonnChat?{" "}
        <Link to="/signup">
          {" "}
          <span className="link">Create Account</span>{" "}
        </Link>
      </div>
      {loading && <Loader />}
    </div>
  );
}
