import axios from "axios";
import { customRedirect, setCookie, removeCookie } from "../utils";
import {
  AUTH_TOKEN_TIMEOUT,
  AUTH_HEADER,
  API_URL,
  TOKEN_KEY,
} from "../utils/constant";

export function setAccessToken(token) {
  setCookie(TOKEN_KEY, token, AUTH_TOKEN_TIMEOUT);
}

export async function registerUser(payload) {
  let resp = await axios.post(`${API_URL}/register`, payload);
  return resp;
}

export async function loginUser(payload) {
  let resp = await axios.post(`${API_URL}/login`, payload);
  return resp;
}

export async function resetPassword(payload) {
  let resp = await axios.post(`${API_URL}/password-reset`, payload);
  return resp;
}

export async function verifyUsername(username) {
  try {
    let resp = await axios.get(`${API_URL}/annon/${username}`);
    if (resp.status) {
      return resp.data.message;
    } else {
      customRedirect("/error");
    }
  } catch (error) {
    customRedirect("/error");
  }
}

export async function sendAnnonMessage(payload) {
  let resp = await axios.post(`${API_URL}/annon-message`, payload);
  return resp;
}

export async function sendFeedback(payload) {
  const formData = new FormData();
  formData.append("feedback", payload?.feedback);
  if (payload?.feedback_image) {
    formData.append("feedback_image", payload?.feedback_image);
  }
  let resp = await axios.post(`${API_URL}/feedback`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return resp;
}

export function logOut() {
  removeCookie("annon_token");
  removeCookie("_cache_");
  window.location.replace("/login");
}

export async function deleteUser(password) {
  try {
    let data = { password };
    console.log(data);
    let resp = await axios.delete(`${API_URL}/user/delete`, {
      data,
      headers: AUTH_HEADER,
    });
    if (resp.data) {
      logOut();
    }
  } catch (error) {
    console.log(error);
    return "incorrect password!";
  }
}

export async function dashboard() {
  let resp = await axios.get(`${API_URL}/dashboard`, { headers: AUTH_HEADER });
  return resp;
}

export async function userMessages() {
  let resp = await axios.get(`${API_URL}/messages`, { headers: AUTH_HEADER });
  return resp;
}

export async function markMessageAsSeen(id) {
  try {
    let resp = await axios.get(`${API_URL}/mark_as_seen/${id}`, {
      headers: AUTH_HEADER,
    });
    //do nothing
  } catch (error) {
    console.log(error);
  }
}

export async function fetchUserRooms() {
  let resp = await axios.get(`${API_URL}/rooms`, { headers: AUTH_HEADER });
  return resp;
}
export async function getMemberEmoji(room_id) {
  let resp = await axios.get(`${API_URL}/room-emoji/${room_id}`);
  return resp;
}

export async function validRoom(id) {
  try {
    let resp = await axios.get(`${API_URL}/room/valid/${id}`);
    if (resp.status) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export async function getRoom(id) {
  try {
    let resp = await axios.get(`${API_URL}/room/${id}`);
    if (resp.status) {
      return resp.data?.data;
    }
  } catch (error) {
    localStorage.removeItem(id);
    window.location.replace("/expired");
  }
}

export async function deleteRoom(id) {
  let resp = await axios.delete(`${API_URL}/room/${id}`, {
    headers: AUTH_HEADER,
  });
  return resp;
}

export async function createRoom(room_name) {
  let data = { name: room_name };
  let resp = await axios.post(`${API_URL}/room/create`, data, {
    headers: AUTH_HEADER,
  });
  return resp;
}

export async function chengePassword(old_password, new_password) {
  let data = { old_password, new_password };
  console.log(data);
  let resp = await axios.post(`${API_URL}/user/change-password`, data, {
    headers: AUTH_HEADER,
  });
  return resp;
}

export async function uploadImage(formData) {
  const resp = await axios.post(`${API_URL}/room/upload-image`, formData);
  return resp;
}
