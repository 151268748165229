import { useRef } from "react";
import { downloadImage, downloadImageFromImgTag } from "../../utils";
import ProgressiveLazyImage from "../ProgressiveImage";

export default function ViewImage({ data, close }) {
  const imgRef = useRef(null);
  return (
    <div className="sendimage viewimage">
      <div id="view-img-options">
        <span class="material-symbols-rounded" onClick={() => close()}>
          close
        </span>
        <span
          class="material-symbols-rounded"
          onClick={() => downloadImageFromImgTag(imgRef.current.firstChild)}
        >
          download
        </span>
      </div>
      <div id="img-container" ref={imgRef}>
        <ProgressiveLazyImage data={data} alt="sendImage" />
      </div>
    </div>
  );
}
