import React, { useEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import "./progressiveImage.scss";

const ProgressiveLazyImage = ({ data, alt, ...props }) => {
  const [src, setSrc] = useState(data.low);
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef(null);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const loadImage = () => {
      const img = new Image();
      img.src = data.high;
      img.onload = () => {
        setSrc(data.high);
        setIsLoaded(true);
      };
    };

    if (inView) {
      loadImage();
    }
  }, [data.high, inView]);

  useEffect(() => {
    const elements = document.getElementsByClassName("progressive-image");
    const lastElement = elements[elements.length - 1];
    if (imgRef.current && imgRef.current === lastElement) {
      const messageContainer =
        document.querySelectorAll(".messageContainer")[0];
      messageContainer.scrollTop = messageContainer.scrollHeight;
    }
  }, [isLoaded]);

  return (
    <img
      ref={(el) => {
        imgRef.current = el;
        ref(el);
      }}
      {...props}
      src={src}
      alt={alt}
      className={`progressive-image ${isLoaded ? "loaded" : ""}`}
      loading="lazy"
    />
  );
};

export default ProgressiveLazyImage;
